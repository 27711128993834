<script setup lang="ts">
import type { MenuItem } from "primevue/menuitem";
import AppMenuItem from "./AppMenuItem.vue";

const props = defineProps<{
    model: MenuItem[];
}>();
</script>

<template>
    <ul class="layout-menu">
        <template v-for="(item, i) in props.model" :key="item">
            <AppMenuItem :item="item" root :index="i" />

            <li class="menu-separator"></li>
        </template>
    </ul>
</template>
