<script lang="ts" setup>
import { inject, onMounted, onUnmounted, toRef, watch } from "vue";
import { mapSymbol } from "@/types";
import { useGeoJSONStore } from "@/store/geojson";
import type { AssetTableItem } from "@/db";
import { PositionEmitter } from "@/plugins/positions";

const props = defineProps<{
    selected?: AssetTableItem | null;
}>();

const selected = toRef(props, "selected");
const map = inject(mapSymbol);
const geoJSONStore = useGeoJSONStore();
const posEmitter = inject(PositionEmitter);
let currentHandler = null;

const handler = () => (imei: string, evt: any) => {
    if (selected?.value?.imei == imei) {
        const hello = geoJSONStore.allPositions.get(selected.value.imei);

        map.value.easeTo({
            center: [parseFloat(hello.longitude), parseFloat(hello.latitude)],
            duration: 1000
        });
    }
};

onUnmounted(() => {
    if (currentHandler !== null) {
        console.log("UNSET UNMOUNT");
        posEmitter.off("*", currentHandler);
    }
});

watch(
    selected,
    async () => {
        if (currentHandler !== null) {
            console.log("UNSET");
            posEmitter.off("*", currentHandler);
        }

        console.log("CHJANGE");
        const hello = geoJSONStore.allPositions.get(selected.value.imei);

        if (!hello) {
            return;
        }

        const isZoomingRn = map.value.isZooming();
        const zoomLevelRn = map.value.getZoom();

        let zoomToLevel = zoomLevelRn > 16 ? zoomLevelRn + 1 : 16;
        if (zoomToLevel > 20) {
            zoomToLevel = undefined;
        }

        console.log("zoomTo", zoomToLevel);

        map.value.easeTo({
            center: [parseFloat(hello.longitude), parseFloat(hello.latitude)],
            zoom: !isZoomingRn ? zoomToLevel : undefined,
            duration: 1000
        });

        currentHandler = handler();
        posEmitter.on("*", currentHandler);
    },
    { immediate: true }
);

onMounted(async () => {
    // Possibly redundant. If watchers activate before this.
    await geoJSONStore.waitForReady();
});
</script>

<template></template>
