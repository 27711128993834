<script setup lang="js">
import AppMenu from "./AppMenu.vue";
import { useLayout } from "@/layout/composables/layout";
import ProfileButton from "@/components/ProfileButton.vue";
import AppLogo from "@/components/AppLogo.vue";

const { layoutState, onSidebarToggle, onAnchorToggle } = useLayout();

let timeout = null;

function onMouseEnter() {
    if (!layoutState.anchored) {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
        onSidebarToggle(true);
    }
}

function onMouseLeave() {
    if (!layoutState.anchored) {
        if (!timeout) {
            timeout = setTimeout(() => onSidebarToggle(false), 300);
        }
    }
}
</script>

<template>
    <div class="layout-sidebar lg:items-center lg:px-8 py-2 max-w-[50vw] md:max-w-[33vw] lg:max-w-full" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave">
        <div class="sidebar-header !pl-0">
            <button class="layout-sidebar-anchor z-20 mb-2" type="button" @click="onAnchorToggle"></button>

            <AppLogo />
        </div>

        <div class="layout-menu-container">
            <AppMenu />
        </div>

        <!-- <AppTopbar /> -->

        <div class="max-lg:hidden flex">
            <ProfileButton />
        </div>

        <!--<div class="flex items-center justify-center m-2">
		  <Link href="/management/trackers">
		  <Button icon="pi pi-box" class="flex-1" label="Unassigned Trackers"></Button>
		  </Link>
		</div>-->
    </div>
</template>

<style>
.st0 {
    fill: #262626;
}
.st1 {
    fill: #523180;
}
.st2 {
    fill: #fffcf8;
}
.st3 {
    fill: #ffffff;
}

.layout-dim .st0 {
    fill: #f8f3ea;
}
.layout-dark .st0 {
    fill: #f8f3ea;
}

.layout-static .layout-sidebar {
    flex-direction: column !important;
}
</style>
