<script setup>
import { ref, watch } from "vue";

const route = {};

const breadcrumbRoutes = ref({});
</script>

<template>
    <nav class="layout-breadcrumb m-0">
        <ol>
            <template v-for="(breadcrumbRoute, i) in breadcrumbRoutes" :key="breadcrumbRoute">
                <li>{{ breadcrumbRoute }}</li>
                <li v-if="i !== breadcrumbRoutes.length - 1" class="layout-breadcrumb-chevron">/</li>
            </template>
        </ol>
    </nav>
</template>
