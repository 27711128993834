import { createApp, h } from "vue";
import PrimeVue from "primevue/config";

import ConfirmationService from "primevue/confirmationservice";
import DialogService from "primevue/dialogservice";
import ToastService from "primevue/toastservice";
import { createPinia } from "pinia";

import { db, DB_KEY } from "@/db";
import { OryPlugin } from "@/plugins/ory";
import { SyncPlugin } from "@/plugins/sync";
import { PositionTracker } from "@/plugins/positions";
import { MapRegistry } from "@/plugins/mapregistry";
import { createInertiaApp } from "@inertiajs/vue3";
import { Config } from "@/utils/build";
import Dexie from "dexie";
import { createI18n } from "vue-i18n";
import TrackThisTheme from "./theme";

import "@/assets/styles.scss";
import "@/assets/tailwind.css";

const pinia = createPinia();

createInertiaApp({
    resolve: (name) => {
        const pages = import.meta.glob("./pages/**/*.vue", { eager: true });
        return pages[`./pages/${name}.vue`] as any;
    },
    setup({ el, App, props, plugin }) {
        console.log("PROPS", props);
        fetch("/bootstrap")
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                const vueApp = createApp({ render: () => h(App, props) });

                vueApp.use(plugin).use(pinia);

                vueApp.provide(Config, {
                    CompanyName: res.CompanyName,
                    DebugMode: res.DebugMode,
                    OryBase: res.OryBase,
                    LogoURL: res.LogoURL,
                    PlatformProvider: res.PlatformProvider
                });

                vueApp.provide(DB_KEY, db);
                vueApp.use(MapRegistry);
                vueApp.use(OryPlugin);
                vueApp.use(SyncPlugin);

                vueApp.use(PrimeVue, {
                    prefix: "p",
                    theme: {
                        preset: TrackThisTheme,
                        options: {
                            darkModeSelector: ".app-dark"
                        }
                    }
                });
                vueApp.use(ToastService);
                vueApp.use(DialogService);
                vueApp.use(ConfirmationService);
                vueApp.use(PositionTracker);

                const i18n = createI18n({
                    numberFormats: {
                        "en-US": {
                            currency: { style: "currency", currency: "USD", notation: "standard" },
                            decimal: { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 },
                            percent: { style: "percent", useGrouping: false },
                            time: { style: "unit", unit: "hour", unitDisplay: "short" }
                        }
                    }
                });

                vueApp.use(i18n);

                (async () => {
                    // Dexie.VersionError may occur with deployment rollbacks, or development.
                    // Delete IndexedDB and refresh page to re-initialize and resolve the problem.
                    db.open().catch(Dexie.VersionError, (e) => {
                        db.delete().then(() => {
                            location.reload();
                        });
                    });
                })();

                vueApp.mount(el);
            });
    }
});
