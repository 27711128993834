<script setup lang="ts">
import { computed, ref, toRaw, toRef, watch } from "vue";
import { FilterMatchMode, FilterOperator } from "@primevue/core/api";
import Wrapper from "./Wrapper.vue";
import type { ContainerItem } from "@/utils/containers";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import { Link } from "@inertiajs/vue3";
import { useAuthStore } from "@/store/auth";
import { capitalized } from "@/utils/strings";
import ContainerTreeSelect from "@/components/ContainerTreeSelect.vue";
import Chip from "primevue/chip";

//import { useRouter } from 'vue-router';
const confirmPopup = useConfirm();
const toast = useToast();

const authStore = useAuthStore();

(async () => {
    if (!authStore.userData) {
        await authStore.session();
    }
})();

const enableChangingSite = ref({});
const enableChangingRole = ref({});
const selectedSite = ref({});

const saving = ref({});

const props = defineProps<{
    users: [];
    containers: { [key: string]: ContainerItem };
    role: string;
}>();

const containerItems = toRef(props, "containers");

const userRoles = ref({});
const options = ref([
    { name: "Administrator", value: 1 },
    { name: "Operator", value: 2 },
    { name: "User", value: 3 },
    { name: "Guest", value: 4 }
]);

const roleMap = {
    1: "administrator",
    2: "operator",
    3: "user",
    4: "guest"
};

const currentUsers = toRef(props, "users");

const saveSite = async (id: string) => {
    const siteId = Object.getOwnPropertyNames(selectedSite?.value?.[id] || {})?.[0];
    if (!siteId) {
        toast.add({
            severity: "error",
            summary: "Failed to update site for user"
        });

        return;
    }

    saving.value[id] = true;

    const rawResponse = await fetch("?changesite=1", {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            id: id,
            site: siteId,
            form: "change-site"
        })
    });

    saving.value[id] = false;
    if (rawResponse.ok) {
        await rawResponse.json();

        // @ts-ignore
        const identity = currentUsers.value.filter((r) => r.id == id)?.[0];

        if (identity) {
            // @ts-ignore
            identity.metadata_public.container_id = siteId;
        }
        toast.add({
            severity: "info",
            summary: "User site has been updated"
        });

        enableChangingSite.value[id] = false;
    } else {
        toast.add({
            severity: "error",
            summary: "Failed to update site for user"
        });
    }
};

const saveRole = async (id: string) => {
    const role = roleMap[userRoles.value[id].value];
    if (!role) {
        toast.add({
            severity: "error",
            summary: "Failed to update role for user"
        });

        return;
    }

    saving.value[id] = true;

    const rawResponse = await fetch("?changerole=1", {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            id: id,
            role: role,
            form: "change-role"
        })
    });

    saving.value[id] = false;

    if (rawResponse.ok) {
        await rawResponse.json();

        // @ts-ignore
        const identity = currentUsers.value.filter((r) => r.id == id)?.[0];

        if (identity) {
            // @ts-ignore
            identity.metadata_public.role = role;
        }
        toast.add({
            severity: "info",
            summary: "User role has been updated"
        });

        enableChangingRole.value[id] = false;
    } else {
        toast.add({
            severity: "error",
            summary: "Failed to update role for user"
        });
    }
};

watch(
    currentUsers,
    () => {
        for (const user of currentUsers.value) {
            // @ts-ignore
            const currentRole = user?.metadata_public?.role;
            if (!currentRole) {
                continue;
            }

            switch (currentRole) {
                case "administrator":
                    // @ts-ignore
                    userRoles.value[user.id] = { name: "Administrator", value: 1 };
                    break;
                case "operator":
                    // @ts-ignore
                    userRoles.value[user.id] = { name: "Operator", value: 2 };
                    break;
                case "user":
                    // @ts-ignore
                    userRoles.value[user.id] = { name: "User", value: 3 };
                    break;
                case "guest":
                    // @ts-ignore
                    userRoles.value[user.id] = { name: "Guest", value: 4 };
                    break;
                default:
                // code block
            }
        }
    },
    { immediate: true }
);

const idxMap = new Map<string, number>();

const changeSite = (id: string, index: number) => {
    if (!idxMap.has(id)) {
        idxMap.set(id, index);
    }
    if (id in enableChangingSite.value) {
        enableChangingSite.value[id] = !enableChangingSite.value[id];
    } else {
        enableChangingSite.value[id] = true;
    }
};

const changeRole = (id: string, index: number) => {
    if (!idxMap.has(id)) {
        idxMap.set(id, index);
    }
    if (id in enableChangingRole.value) {
        enableChangingRole.value[id] = !enableChangingRole.value[id];
    } else {
        enableChangingRole.value[id] = true;
    }
};

const deleteUserConfirm = (event: any, data: any) => {
    confirmPopup.require({
        target: event.target,
        message: "Delete user: " + data.traits.email + "?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
            deleteUser(data.id);
        },
        reject: () => {
            // do nothing
        }
    });
};

const deleteUser = async (id: string) => {
    if (!isAdmin.value) {
        toast.add({
            severity: "error",
            summary: "Invalid role"
        });
        return;
    }

    const rawResponse = await fetch("/management/users/delete", {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            id: id
        })
    });

    if (rawResponse.ok) {
        toast.add({
            severity: "success",
            summary: "User has been deleted successfully"
        });

        // @ts-ignore
        const deletedUserIndex = currentUsers.value.findIndex((user) => user.id === id);
        currentUsers.value.splice(deletedUserIndex, 1);
    } else {
        toast.add({
            severity: "error",
            summary: "Failed to delete user"
        });
    }
};

const tableRef = ref(null);
const filterTable = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
});

const formatDate = (value) => {
    return new Date(value).toLocaleDateString("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
    });
};

const menu = ref(null);

const toggleMenu = (event: Event) => {
    menu.value.toggle(event);
};

/*const confirm = (event: Event) => {
    confirmPopup.require({
        target: event.target,
        message: "This will send an email to the user. Are you sure?",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
            toast.add({
                severity: "info",
                summary: "Password Reset",
                detail: "An email will be sent off shortly asking user to set a new password.",
                life: 3000
            });
        }
    });
};*/
const editRole = ref(false);
const activateEditRole = (id: string) => {
    console.log(id);
    editRole.value = editRole.value!;
};

const isAdmin = computed(() => authStore?.userData?.identity?.metadata_public?.role === "administrator");
const selfUserId = computed(() => authStore?.userData?.identity?.id);

function duration(expirationTimeUnix: number): number {
    const currentTimeInSeconds = Math.floor(Date.now() / 1000);
    return expirationTimeUnix - currentTimeInSeconds;
}

function formatExpirationDuration(expirationTimeUnix: number, expiredSuffix: string): string {
    const durationInSeconds = duration(expirationTimeUnix);
    if (durationInSeconds <= 0) {
        return expiredSuffix !== null ? `Expired - ${expiredSuffix}` : "Expired";
    }
    const minutes = Math.floor(durationInSeconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    let result: string;
    if (days > 0) {
        result = `${days} day${days > 1 ? "s" : ""}`;
    } else if (hours > 0) {
        result = `${hours} hour${hours > 1 ? "s" : ""}`;
    } else if (minutes > 0) {
        result = `${minutes} minute${minutes > 1 ? "s" : ""}`;
    } else {
        result = "< 1 minute";
    }

    return `Expires In ${result}`;
}

function expirationSeverity(expirationTimeUnix: number): any {
    const durationInSeconds = duration(expirationTimeUnix);
    if (durationInSeconds <= 0) {
        return "danger";
    }
    return "warn";
}

function expirationLabel(expiredMessage: string, notExpiredMessage: string, expirationTimeUnix: number): string {
    let durationInSeconds = duration(expirationTimeUnix);
    if (durationInSeconds <= 0) {
        return expiredMessage;
    }
    return notExpiredMessage;
}
</script>

<template>
    <Wrapper>
        <Toast />

        <h1 class="font-bold text-5xl m-4">Users</h1>

        <div v-if="role == 'user'">You do not have permission to see this page.</div>

        <DataTable
            v-if="role != 'user'"
            ref="tableRef"
            :value="currentUsers"
            paginator
            :rows="10"
            showCurrentPageReport
            responsiveLayout="scroll"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            :rowsPerPageOptions="[10, 25, 50]"
            :globalFilterFields="['traits.email']"
            v-model:filters="filterTable"
        >
            <template #header>
                <div class="flex flex-wrap gap-2 items-center justify-between">
                    <span class="p-input-icon-left w-full sm:w-80 order-1 sm:order-none">
                        <InputGroup>
                            <InputGroupAddon>
                                <i class="pi pi-search"></i>
                            </InputGroupAddon>
                            <InputText v-model="filterTable.global.value" placeholder="Global Search" class="w-full" />
                        </InputGroup>
                    </span>
                    <Link href="/management/users/create" v-if="isAdmin">
                        <Button icon="pi pi-user-plus" class="p-button-outlined" label="Invite User"></Button>
                    </Link>
                </div>
            </template>
            <Column field="name" header="Email" headerClass="whitespace-nowrap">
                <template #body="{ data }">
                    {{ data.traits.email }}
                </template>
            </Column>
            <Column field="status" header="Status" headerClass="whitespace-nowrap">
                <template #body="{ data }">
                    <Tag :value="capitalized(data.state)" class="m-1"></Tag>
                    <Tag
                        class="expiration-label"
                        v-tooltip.top="{ value: formatExpirationDuration(data.metadata_admin?.invite_expiration, 'To resend invitation, first delete the user account then re-invite'), position: 'top' }"
                        :value="expirationLabel('Invitation Expired', 'Invitation Sent', data.metadata_admin?.invite_expiration)"
                        :severity="expirationSeverity(data.metadata_admin?.invite_expiration)"
                        v-if="data.metadata_admin?.invite_jti"
                    />
                </template>
            </Column>

            <Column header="Home Site" headerClass="whitespace-nowrap">
                <template #body="{ data }">
                    <div v-if="enableChangingSite[data.id]" class="inline-flex items-center">
                        <!--<Chip label="asfasffasfsfsafsa" />
                        <Button icon="pi pi-check" class="p-button-rounded p-button-text p-button-secondary">
                            <i class="pi pi-pencil" style="font-size: 1rem"></i>
                        </Button>
                        -->
                        <ContainerTreeSelect :withDomain="true" v-model="selectedSite[data.id]" :single="true" placeholder="None selected" :containers="containerItems" />
                        <Button type="button" class="" label="" icon="pi pi-check" :loading="saving[data.id]" @click="saveSite(data.id)" />
                    </div>
                    <Chip v-if="!enableChangingSite[data.id] && userRoles[data.id]?.name != 'Administrator'" :label="containerItems?.[data.metadata_public.container_id]?.name" />
                </template>
            </Column>
            <Column header="Role" headerClass="whitespace-nowrap">
                <template #body="{ data }">
                    <!--<Chip v-if="data.metadata_public.role" :label="data.metadata_public.role" />-->
                    <div v-if="enableChangingRole[data.id]" class="inline-flex items-center">
                        <SelectButton class="p-button-sm" v-model="userRoles[data.id]" :options="options" optionLabel="name" aria-labelledby="multiple" />
                        <Button type="button" class="" label="" icon="pi pi-check" :loading="saving[data.id]" @click="saveRole(data.id)" />
                    </div>
                    <!--<Button icon="pi pi-check" @click="activateEditRole(data.id)" class="p-button-rounded p-button-text p-button-secondary">
                        <i class="pi pi-pencil" style="font-size: 1rem"></i>
                    </Button>
                    -->
                    <Chip v-if="!enableChangingRole[data.id]" :label="userRoles[data.id]?.name" />
                </template>
            </Column>
            <Column field="actions" header="Actions" headerClass="whitespace-nowrap">
                <template #body="{ data, index }">
                    <ConfirmPopup></ConfirmPopup>
                    <!--<Button ref="popup" @click="confirm($event)" size="small" v-if="isAdmin" icon="pi pi-check"
                            label="Reset Password" class="p-button-sm mr-2"></Button>-->
                    <Button
                        v-if="isAdmin && userRoles[data.id]?.name != 'Administrator'"
                        ref="popup"
                        :disabled="enableChangingSite[data.id]"
                        @click="changeSite(data.id, index)"
                        size="small"
                        icon="pi pi-box"
                        label="Change Site"
                        class="p-button-sm mr-2"
                    ></Button>

                    <Button ref="popup" :disabled="enableChangingRole[data.id]" @click="changeRole(data.id, index)" size="small" v-if="isAdmin && data.id != selfUserId" icon="pi pi-users" label="Change Role" class="p-button-sm mr-2"></Button>

                    <Button
                        ref="popup"
                        :disabled="enableChangingRole[data.id]"
                        @click="deleteUserConfirm($event, data)"
                        size="small"
                        v-if="isAdmin && data.id != selfUserId"
                        icon="pi pi-user"
                        label="Delete"
                        class="p-button-sm p-button-danger mr-2"
                    ></Button>

                    <span class="p-buttonset">
                        <!--
                        <Menu ref="menu" :model="overlayMenuItems" :popup="true" />
                        <Button type="button" label="Options" icon="pi pi-angle-down" @click="toggleMenu" style="width: auto" />

                       <Button label="Change Email Address"></Button>
                        <Button label="Change Name"></Button>
                        <Button label="Send Email">mailto:</Button>
                        -->
                    </span>
                </template>
            </Column>
        </DataTable>
    </Wrapper>
</template>

<style scoped lang="scss">
.expiration-label {
    cursor: help;
}
</style>
