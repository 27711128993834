import type { InjectionKey, Plugin } from "vue";
import { useLatestPositionsStore } from "@/store/positions";
import { type Coordinator, db } from "@/db";
import { useGeoJSONStore } from "@/store/geojson";
import mitt from "mitt";
import type { FeatureCollection } from "geojson";

export type PositionUpdate = {
    Coords: [number, number];
};
const emitter = mitt<PositionUpdate>();

export const PositionEmitter = Symbol() as InjectionKey<typeof emitter>;

export const geoJsons = {
    type: "FeatureCollection",
    features: []
} as FeatureCollection;

/*
 * PositionTracker was born because Dexie.JS observables re-trigger on entire result-sets instead of deep refs.
 */
export const PositionTracker: Plugin = {
    async install(app) {
        app.provide(PositionEmitter, emitter);

        const posStore = useLatestPositionsStore();
        const geojsonStore = useGeoJSONStore();
        const starting = await db.coordinator.toArray();

        geojsonStore.init(starting);
        posStore.init(starting);

        db.on("changes", function (changes) {
            changes.forEach(function (change) {
                switch (change.type) {
                    case 1: // CREATED
                        if (change.table !== "coordinator") {
                            return;
                        }

                        emitter.emit(change.key, change.obj);

                        posStore.add(change.key, change.obj);
                        geojsonStore.add(change.key, change.obj);
                        break;
                    case 2: // UPDATED
                        if (change.table !== "coordinator") {
                            return;
                        }

                        emitter.emit(change.key, change.mods);

                        posStore.update(change.key, change.mods as any as Partial<Coordinator>);
                        geojsonStore.update(change.key, change.mods as any as Partial<Coordinator>);

                        //console.log('An object with key ' + change.key + ' was updated with modifications: ' + JSON.stringify(change.mods));
                        break;
                    case 3: // DELETED
                        break;
                }
            });
        });
    }
};
