<script setup>
import { useLayout } from "@/layout/composables/layout";
import Silhouette from "@/assets/image/silhouette.png";

const { onProfileSidebarToggle } = useLayout();
</script>

<template>
    <!-- todo: remove specificity -->
    <Button type="menu" class="!rounded-full !p-0 max-w-10 !border-none" @click="onProfileSidebarToggle">
        <img :src="Silhouette" alt="Profile" style="" class="" />
    </Button>
</template>
