import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "128pt",
  height: "128pt",
  viewBox: "0 0 128 128"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", { fill: "#dee0e3" }, [
      _createElementVNode("path", { d: "M121 72h-5v-6.602a4 4 0 0 0-2.66-3.757l-26.121-9.32-12.88-16.763A4 4 0 0 0 71.16 34H40.84a4 4 0 0 0-3.16 1.558l-12.82 16.66-17.641 3.5a4 4 0 0 0-3.219 4V78.5a4 4 0 0 0 3.059 3.88l6.8 1.64h.301a12 12 0 0 0 2.899 6H3.999v4h120v-4.02H96.923a12.03 12.03 0 0 0 2.898-6H121a3 3 0 0 0 3-3v-6a3 3 0 0 0-3-3zm-9 0h-8v-4h8zM71.16 38 82 52H58V38zm-30.32 0H54v14H30zM8 66h4v4H8zm0 12.422V74h4a3.997 3.997 0 0 0 4-4v-4a3.997 3.997 0 0 0-4-4H8v-2.36L26.199 56H54v24H37.82a12.004 12.004 0 0 0-11.809-9.875A12.004 12.004 0 0 0 14.199 80zM76.18 84a12.03 12.03 0 0 0 2.898 6H34.922a12.03 12.03 0 0 0 2.899-6zM18 82a7.998 7.998 0 0 1 13.656-5.656A7.998 7.998 0 0 1 26 90a8 8 0 0 1-8-8zm62 0a7.998 7.998 0 0 1 13.656-5.656A7.998 7.998 0 0 1 88 90a8 8 0 0 1-8-8m19.82-2c-1.008-5.742-5.992-9.93-11.82-9.93S77.188 74.258 76.18 80H58V56h27.66L108 64h-4c-2.21 0-4 1.79-4 4v4a3.997 3.997 0 0 0 4 4h8v4zM120 80h-4v-4h4z" }),
      _createElementVNode("path", { d: "M62 60h6v4h-6zM28 60h6v4h-6zM26 78a4 4 0 0 0-3.695 2.469c-.621 1.496-.278 3.215.867 4.36s2.863 1.488 4.36.866A4 4 0 0 0 30 82a3.997 3.997 0 0 0-4-4m0 6v-2zM88 78a4 4 0 0 0-3.695 2.469c-.621 1.496-.278 3.215.867 4.36s2.863 1.488 4.36.866A4 4 0 0 0 92 82a3.997 3.997 0 0 0-4-4m0 6v-2z" })
    ], -1)
  ])))
}
export default { render: render }