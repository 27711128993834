<script setup lang="ts">
import Wrapper from "@/pages/management/Wrapper.vue";
import { computed, ref, toRef, watch, inject } from "vue";
import { SyncWorker } from "@/plugins/sync";
import type { Synchroniser } from "@/plugins/sync";
import { FilterMatchMode } from "@primevue/core/api";
import Chip from "primevue/chip";
import ContainerTreeSelect from "@/components/ContainerTreeSelect.vue";
import { useToast } from "primevue/usetoast";
import { useAuthStore } from "@/store/auth";
import { Link } from "@inertiajs/vue3";
import { useUrlSearchParams } from "@vueuse/core";
import { AssetTypeMapping } from "@/db";

const authStore = useAuthStore();

const synchroniser = inject<Synchroniser>(SyncWorker);

interface ContainerItemV2 {
    id: string;
    name: string;
    asset_count: number;
    parent_container_id?: string;
}
type NewContainerlist = { [key: string]: ContainerItemV2 };

interface AnAsset {
    id: string;
    name: string;
    container_id: string;
    container_name: string;
    tracker_serial?: string;
    linked_id: string;
    linked_alias: string;
    type: string;
}

const props = defineProps<{
    assets?: Array<AnAsset>;
    containers: NewContainerlist;
}>();
const containerItems = toRef(props, "containers");
const assets = toRef(props, "assets");

const toast = useToast();

const filters = ref();
const initFilters = () => {
    filters.value = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        is_linked: { value: null, matchMode: FilterMatchMode.EQUALS },
        container_id: { value: null, matchMode: FilterMatchMode.IN }
    };
};

const changeSite = (id: string, index: number) => {
    if (id in enableChangingSite.value) {
        enableChangingSite.value[id] = !enableChangingSite.value[id];
    } else {
        enableChangingSite.value[id] = true;
    }
};

initFilters();

const wait = async (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds));

const clearFilter = () => {
    initFilters();
};

const params = useUrlSearchParams("history");

// ?keyword=TTKMK... will apply global search filter
watch(
    () => params.keyword,
    (qs) => {
        if (!qs?.length) {
            return;
        }

        filters.value.global.value = qs;
    },
    { immediate: true }
);

const enableChangingSite = ref({});
const enableRenaming = ref({});
const selectedSite = ref({});
const saving = ref({});

const newAssetName = ref({});

const rename = (id: string, index: number, currentName: string) => {
    newAssetName.value[id] = currentName;

    if (id in enableRenaming.value) {
        enableRenaming.value[id] = !enableRenaming.value[id];
    } else {
        newAssetName[id] = currentName;
        enableRenaming.value[id] = true;
    }
};

const saveSiteChange = async (data: AnAsset) => {
    saving.value[data.id] = true;
    const siteId = Object.getOwnPropertyNames(selectedSite?.value?.[data.id] || {})?.[0];
    if (!siteId) {
        return;
    }

    const rawResponse = await fetch("?form=site", {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            id: data.id,
            site: siteId
        })
    });
    saving.value[data.id] = false;

    if (rawResponse.ok) {
        await rawResponse.json();
        enableChangingSite.value[data.id] = false;

        data.container_name = containerItems.value[siteId].name;
        toast.add({
            severity: "info",
            summary: "Asset site has changed"
        });

        await wait(500);
        synchroniser.resync();
    }
};

const cancelSiteChange = (data: AnAsset) => {
    enableChangingSite.value[data.id] = false;
};

const cancelRename = (data: AnAsset) => {
    enableRenaming.value[data.id] = false;
};

const containerLocation = (container_id: string) => {
    let currentContainer = containerItems.value[container_id];

    if (!currentContainer) {
        return "";
    }

    let stringParts = [];

    while (currentContainer) {
        stringParts.push(currentContainer.name);

        currentContainer = containerItems.value[currentContainer.parent_container_id];
    }

    if (stringParts.length <= 1) {
        return "";
    }

    stringParts.pop();
    return stringParts.reverse();
};

const saveRename = async (data: AnAsset) => {
    saving.value[data.id] = true;

    const oldName = data.name;

    const rawResponse = await fetch("?form=name", {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            id: data.id,
            newName: newAssetName.value[data.id]
        })
    });

    saving.value[data.id] = false;

    if (rawResponse.ok) {
        await rawResponse.json();

        enableRenaming.value[data.id] = false;
        // @ts-ignore
        data.name = newAssetName.value[data.id];

        toast.add({
            severity: "info",
            summary: `Asset '${oldName}' has been renamed to '${newAssetName.value[data.id]}'`
        });

        await wait(500);
        synchroniser.resync();
    }
};

const linkToMap = (ttSerial: string) => {
    return `/map/${ttSerial}`;
};

const linkToTrackerList = (ttSerial: string) => {
    return `/management/trackers?keyword=${ttSerial}`;
};

const isAdmin = computed(() => authStore?.userData?.identity?.metadata_public?.role === "administrator");

const assetsReactive = computed(() => {
    if (!assets.value) {
        return [];
    }

    return assets.value.map((obj) => {
        const containers = containerLocation(obj.container_id);
        return {
            ...obj,
            // @ts_ignore
            is_linked: obj.linked_id?.length > 1,
            containers: containers
        };
    });
});

const filteredRowCount = ref(0);

const cb = (v: Array<any>) => {
    filteredRowCount.value = v.length;
};
const intl = new Intl.NumberFormat("en-AU");

const filtersApplied = computed(() => {
    if (!filters.value) {
        return 0;
    }

    // @ts-ignore
    return Object.values(filters.value)
        .map((f: any) => f?.value !== null)
        .filter(Boolean).length;
});
</script>
<template>
    <Wrapper id="assetlist">
        <h1 class="font-bold text-5xl m-4">Assets</h1>
        <DataTable
            v-model:filters="filters"
            filterDisplay="menu"
            data-key="id"
            sortField="name"
            :sortOrder="1"
            :globalFilterFields="['name', 'tracker_serial']"
            @value-change="cb"
            showGridlines
            :value="assetsReactive"
            paginator
            :rows="10"
            :rowsPerPageOptions="[10, 20, 50]"
            tableStyle="width: 100%"
        >
            <template #paginatorstart>
                <span class="italic" v-if="filtersApplied > 0">Displaying {{ intl.format(filteredRowCount) }} of {{ intl.format(assetsReactive.length) }}</span>
                <span class="italic" v-else>{{ assetsReactive.length }} total</span>
            </template>
            <template #paginatorend> </template>

            <template #header>
                <div class="flex justify-between">
                    <Button v-if="filtersApplied" type="button" label="Clear filters" @click="clearFilter()"> <span class="pi pi-filter-slash"></span>Clear {{ filtersApplied }} Filters </Button>
                    <Button outlined icon="pi pi-filter-slash" v-else disabled label="Clear Filters"></Button>
                    <InputGroup class="basis-80">
                        <InputGroupAddon>
                            <i class="pi pi-search"></i>
                        </InputGroupAddon>
                        <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                    </InputGroup>
                </div>
            </template>
            <Column header="Name" field="name" :showFilterMatchModes="false" sortable style="width: auto">
                <template #body="{ data, index }">
                    <div class="flex justify-between">
                        <Chip v-if="!enableRenaming[data.id]" class="whitespace-nowrap">{{ data.name }}</Chip>
                        <div v-if="enableRenaming[data.id]" class="inline-flex items-center">
                            <InputText type="text" v-model="newAssetName[data.id]" placeholder="Default"></InputText>

                            <ButtonGroup class="ml-2">
                                <Button size="small" type="button" class="" label="" icon="pi pi-check" :loading="saving[data.id]" @click="saveRename(data)" />
                                <Button size="small" type="button" class="" label="" icon="pi pi-times-circle" :loading="saving[data.id]" @click="cancelRename(data)" />
                            </ButtonGroup>
                        </div>
                        <Button size="medium" ref="popup" v-if="isAdmin" :disabled="enableRenaming[data.id]" @click="rename(data.id, index, data.name)" icon="pi pi-pencil" rounded outlined class="mr-2 ml-4 !p-2"></Button>
                    </div>
                </template>
            </Column>
            <Column header="Type" :showFilterMatchModes="false" field="type" sortable style="width: 10%">
                <template #body="{ data, index }">
                    {{ AssetTypeMapping[data.type] }}
                </template>
            </Column>

            <Column header="Site" field="container_id" :showFilterMatchModes="false" class="flex justify-between" style="width: auto">
                <template #filter="{ filterModel }">
                    <ContainerTreeSelect v-model="filterModel.value" :scalar="true" placeholder="None selected" :containers="containerItems" />
                </template>

                <template #body="{ data, index }">
                    <div v-if="enableChangingSite[data.id]" class="flex justify-between items-center">
                        <ContainerTreeSelect v-model="selectedSite[data.id]" :single="true" placeholder="None selected" :containers="containerItems" class="grow w-1/2" />
                        <ButtonGroup class="flex-none w-1/2">
                            <Button size="small" type="button" class="" label="" icon="pi pi-check" :loading="saving[data.id]" @click="saveSiteChange(data)" />
                            <Button size="small" type="button" class="" label="" icon="pi pi-times-circle" :loading="saving[data.id]" @click="cancelSiteChange(data)" />
                        </ButtonGroup>
                    </div>
                    <Chip v-if="data?.containers && !enableChangingSite[data.id]">
                        {{ data?.containers?.join(" > ") }}
                    </Chip>
                    <span v-else></span>
                    <Button size="medium" ref="popup" v-if="isAdmin" :disabled="enableChangingSite[data.id]" @click="changeSite(data.id, index)" rounded outlined icon="pi pi-pencil" class="justify-end self-center flex-none mr-2 ml-4 !p-2"></Button>
                </template>
            </Column>
            <Column header="Hardware" :showFilterMatchModes="false" style="width: auto">
                <template #body="{ data, index }">
                    <Link :href="linkToTrackerList(data.tracker_serial)">
                        <Chip icon="pi pi-external-link" v-if="data.tracker_serial" :label="data.tracker_serial" class="whitespace-nowrap" />
                    </Link>
                </template>
            </Column>
            <Column header="Connector" field="is_linked" dataType="boolean" style="width: 14%">
                <template #body="{ data, index }">
                    <Chip v-tooltip="'ID  ' + data.linked_id" v-if="data.linked_id" class="whitespace-nowrap">
                        <span class="text-green-500 pi pi-check-circle justify-center"></span>
                        Syrinx > {{ data.linked_alias.length > 0 ? data.linked_alias : data.linked_id }}
                    </Chip>
                    <Chip v-tooltip="'No Syrinx association'" v-if="!data.linked_id">
                        <span class="text-red-400 pi pi-times-circle justify-center"></span>
                    </Chip>
                </template>
                <template #filter="{ filterModel }">
                    <Checkbox v-model="filterModel.value" :indeterminate="filterModel.value === null" binary />
                </template>
            </Column>
            <Column header="" style="width: 5%; min-width: 120px">
                <template #body="{ data, index }">
                    <div class="justify-center flex">
                        <Link preserve-state :href="linkToMap(data.tracker_serial)" class="font-bold rounded-border">
                            <Button size="medium" ref="popup" icon="pi pi-map" label="Map" class="mr-2 !p-2"></Button>
                        </Link>
                        <!--<Button v-if="!data.asset_id" icon="pi pi-pencil" outlined rounded class="mr-2" label="Assign" />-->
                    </div>
                </template>
            </Column>
        </DataTable>
    </Wrapper>
</template>
<style lang="scss">
#assetlist .p-datatable table {
    table-layout: auto !important;
}

.p-chip-icon.pi.pi-check-circle {
    color: text-green-500 !important;
}
</style>
