<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, watch } from "vue";
import AppTopbar from "./AppTopbar.vue";
import AppSidebar from "./AppSidebar.vue";
import AppProfileSidebar from "./AppProfileSidebar.vue";
import AppBreadCrumb from "./AppBreadcrumb.vue";
import { useLayout } from "@/layout/composables/layout";

const { layoutConfig, layoutState, watchSidebarActive, unbindOutsideClickListener } = useLayout();

onMounted(() => {
    watchSidebarActive();
});

onBeforeUnmount(() => {
    unbindOutsideClickListener();
});

const containerClass = computed(() => {
    return {
        "layout-light": !layoutConfig.darkTheme,
        "layout-dark": layoutConfig.darkTheme,
        "layout-colorscheme-menu": layoutConfig.menuTheme === "colorScheme",
        "layout-primarycolor-menu": layoutConfig.menuTheme === "primaryColor",
        "layout-transparent-menu": layoutConfig.menuTheme === "transparent",
        "layout-overlay": layoutConfig.menuMode === "overlay",
        "layout-static": layoutConfig.menuMode === "static",
        "layout-slim": layoutConfig.menuMode === "slim",
        "layout-slim-plus": layoutConfig.menuMode === "slim-plus",
        "layout-horizontal": layoutConfig.menuMode === "horizontal",
        "layout-reveal": layoutConfig.menuMode === "reveal",
        "layout-drawer": layoutConfig.menuMode === "drawer",
        "layout-static-inactive": layoutState.staticMenuDesktopInactive && layoutConfig.menuMode === "static",
        "layout-overlay-active": layoutState.overlayMenuActive,
        "layout-mobile-active": layoutState.staticMenuMobileActive,
        "layout-sidebar-active": layoutState.sidebarActive,
        "layout-sidebar-anchored": layoutState.anchored
    };
});
watch(
    containerClass,
    () => {
        const test = { ...layoutState };
        console.log(test);
    },
    { immediate: true }
);
</script>

<template>
    <div :class="['layout-container', { ...containerClass }]">
        <AppSidebar />

        <div>
            <AppTopbar />
            <AppBreadCrumb class="content-breadcrumb"></AppBreadCrumb>
            <div class="layout-content p-8">
                <slot></slot>
            </div>
        </div>

        <AppProfileSidebar />

        <Toast></Toast>
        <div class="layout-mask"></div>
    </div>
</template>
