<script setup lang="ts">
import Wrapper from "@/pages/management/Wrapper.vue";
import { onMounted, ref } from "vue";
import { Link } from "@inertiajs/vue3";

const props = defineProps<{
    scheduled_count: number;
    aaa: any;
    overdue: number;
    overdue_per_site: { [key: string]: number };
    due_soon: number;
}>();

const linkToMap = (ttSerial: string) => {
    return `/map/${ttSerial}`;
};

const pieOptions = ref({});
const pieData = ref({});
onMounted(() => {
    pieData.value = {
        labels: [],
        datasets: [
            {
                data: []
            }
        ]
    };

    for (const [k, v] of Object.entries(props?.overdue_per_site ?? {})) {
        // @ts-ignore
        pieData.value.labels.push(k);

        // @ts-ignore
        pieData.value.datasets[0].data.push(v);
    }

    setChartData();
});

const setChartData = () => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--p-surface-300");
    pieOptions.value = {
        animation: {
            duration: 0
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                labels: {
                    color: textColor,
                    usePointStyle: true,
                    font: {
                        weight: 700
                    },
                    padding: 28
                },
                position: "bottom"
            }
        }
    };
};
</script>
<template>
    <Wrapper>
        <h1 class="font-bold text-5xl m-4">Servicing</h1>

        <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 md:col-span-6 xl:col-span-4">
                <div class="card h-full">
                    <span class="font-semibold text-lg">Assets Tracked</span>
                    <div class="flex justify-between items-start mt-4">
                        <div class="w-6/12">
                            <span class="text-4xl font-bold text-surface-900 dark:text-surface-0">{{ scheduled_count }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-span-12 md:col-span-6 xl:col-span-4">
                <div class="card h-full">
                    <span class="font-semibold text-lg">Overdue</span>
                    <div class="flex justify-between items-start mt-4">
                        <div class="w-6/12">
                            <span class="text-4xl font-bold text-surface-900 dark:text-surface-0">{{ overdue }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-span-12 md:col-span-6 xl:col-span-4">
                <div class="card h-full">
                    <span class="font-semibold text-lg">Due Shortly</span>
                    <div class="flex justify-between items-start mt-4">
                        <div class="w-6/12">
                            <span class="text-4xl font-bold text-surface-900 dark:text-surface-0">{{ due_soon }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-span-12 xl:col-span-4">
                <div class="card h-full">
                    <div class="text-surface-900 dark:text-surface-0 text-xl font-semibold mb-12">Overdue By Site</div>
                    <Chart type="pie" :data="pieData" :height="500" :options="pieOptions"></Chart>
                </div>
            </div>
            <div class="col-span-12 lg:col-span-8">
                <div class="card">
                    <div class="flex flex-col md:flex-row md:items-start md:justify-between mb-4">
                        <div class="text-surface-900 dark:text-surface-0 text-xl font-semibold mb-4 md:mb-0">Overdue Assets</div>
                    </div>
                    <DataTable :value="aaa" scrollable rowGroupMode="subheader" groupRowsBy="containerName" :rows="10" sortField="containerName" :sortOrder="1" scrollHeight="600px" sortMode="single" reresponsiveLayout="scroll">
                        <template #empty> No overdue assets.</template>
                        <template #groupheader="slotProps">
                            <div class="flex items-center gap-2">
                                <h5 class="font-bold text-2xl m-1">{{ slotProps.data.containerName }}</h5>
                            </div>
                        </template>

                        <Column field="name" header="Name" :headerStyle="{ minWidth: '12rem' }"> </Column>
                        <Column field="containerName" header="Site" sortable :headerStyle="{ minWidth: '10rem' }"> </Column>
                        <Column field="overdue_by_hours" header="Overdue By" sortable :headerStyle="{ minWidth: '10rem' }">
                            <template #body="{ data }">
                                <Tag value="Invitation Sent" severity="danger" style="font-size: 14px"> {{ new Intl.NumberFormat("en-AU").format(data.overdue_by_hours) }} hours </Tag>
                            </template>
                        </Column>

                        <Column field="due_at_hours" header="Service Due Hours" sortable :headerStyle="{ minWidth: '10rem' }">
                            <template #body="{ data }"> {{ new Intl.NumberFormat("en-AU").format(data.due_at_hours) }} hours </template>
                        </Column>
                        <Column field="current_hours" header="Est. Meter Reading" sortable :headerStyle="{ minWidth: '10rem' }">
                            <template #body="{ data, index }"> {{ new Intl.NumberFormat("en-AU").format(data.current_hours) }} hours </template>
                        </Column>

                        <Column class="text-center">
                            <template #body="{ data, index }">
                                <Link preserve-state :href="linkToMap(data.serial)" class="font-bold rounded-border">
                                    <Button outlined rounded ref="popup" icon="pi pi-map" class="mr-2"></Button>
                                </Link>
                            </template>
                        </Column>
                        <template #expansion="slotProps">
                            <div class="p-4">
                                <h5>Orders for {{ slotProps.data.name }}</h5>
                            </div>
                        </template>
                    </DataTable>
                </div>
            </div>
        </div>
    </Wrapper>
</template>
