<script setup lang="ts">
import Wrapper from "@/pages/management/Wrapper.vue";
import type { TreeNode } from "primevue/treenode";
import { computed, inject, ref, shallowRef, toRaw, toRefs } from "vue";
import type { Ref } from "vue";
import * as yup from "yup";
import { useToast } from "primevue/usetoast";
import { useIntervalFn, useTimeAgo } from "@vueuse/core";
import { Config } from "@/utils/build";
import { router } from "@inertiajs/vue3";
import { DateTime, Duration } from "luxon";
import UtilisationReportResults from "@/pages/reporting/UtilisationReportResults.vue";
import ServicingReportResultPage from "@/pages/reporting/ServicingReportResults.vue";
import type { ReportResult, ReportPerDayResult, ServicingReportResult } from "@/types";
import UtilisationIndicator from "./UtilisationIndicator.vue";

interface AnAsset {
    id: string;
    name: string;
    container_id: string;
    container_name: string;
    is_installed?: boolean;
    tracker_serial?: string;
    linked_id?: string;
    type: string;
}

const selectedReport = ref("util");

const selectReport = (report: string) => {
    if (report == selectedReport.value) {
        selectedReport.value = "";
        return;
    }
    selectedReport.value = report;
};

type NewContainerlist = { [key: string]: ContainerItem };

interface ContainerItem {
    id: string;
    name: string;
    parent_container_id: string;
}

const configuration = inject(Config);
const selectedTreeTableValue = ref([]);
const toast = useToast();

const props = defineProps<{
    assets?: AnAsset[];
    containers: NewContainerlist;
    warning?: string;
}>();

const { containers } = toRefs(props);

if (props.warning) {
    const { pause, resume, isActive } = useIntervalFn(() => {
        router.visit("", { method: "get" });
    }, 30000);
}

const preparedAssets = new Map<string, AnAsset[]>();
for (const a of props.assets ?? []) {
    if (!preparedAssets.has(a.container_id)) {
        preparedAssets.set(a.container_id, []);
    }

    preparedAssets.get(a.container_id).push(a);
}

const loadNewContainers = (newTree: Ref<NewContainerlist>) => {
    const cNewCopy = JSON.parse(JSON.stringify(toRaw(newTree.value)));

    //const nodes = Array<TreeNode>()
    const nodes = new Map<string, TreeNode>();
    let rootNode: TreeNode;

    for (const cntnID of Object.keys(cNewCopy)) {
        if (!cNewCopy[cntnID].hasOwnProperty("parent_container_id") || cNewCopy[cntnID].parent_container_id == "") {
            rootNode = {
                key: cNewCopy[cntnID].id,
                data: {
                    name: cNewCopy[cntnID].name,
                    size: "~",
                    actions: "",
                    asset_count: cNewCopy[cntnID]?.asset_count
                },
                label: cNewCopy[cntnID].name,
                children: []
            } as TreeNode;
            nodes.set(cNewCopy[cntnID].id, rootNode);
            delete cNewCopy[cntnID];
        }
    }

    let i = 0;

    while (Object.values(cNewCopy).length > 0) {
        if (Object.values(cNewCopy).length == 0) {
            break;
        }

        i++;
        if (i > 1000) {
            console.log("unsafe break");
            break;
        }

        for (const cntnID of Object.keys(cNewCopy)) {
            const parent = nodes.get(cNewCopy[cntnID].parent_container_id);
            if (parent) {
                const newNode = {
                    key: cNewCopy[cntnID].id,
                    data: {
                        name: cNewCopy[cntnID].name,
                        asset_count: cNewCopy[cntnID]?.asset_count,
                        actions: ""
                    },
                    label: cNewCopy[cntnID].name,
                    children: []
                } as TreeNode;

                if (preparedAssets.has(cntnID)) {
                    newNode.children = preparedAssets.get(cntnID).map((b) => {
                        return {
                            key: "asset:" + b.id,
                            data: {
                                name: b.name,
                                actions: ""
                            },
                            label: b.name,
                            children: []
                        };
                    });
                }

                nodes.set(cntnID, newNode);
                parent.children.push(newNode);
                delete cNewCopy[cntnID];
            }
        }
    }

    return [rootNode];
};

// NEW

let containerNodes = ref<TreeNode[]>(loadNewContainers(containers));

const selectedValue = shallowRef(null);

const schema = yup.object({
    newSiteName: yup.string().required().min(3),
    selectedValue: yup.string().required()
});

const visible = ref(false);
const newSiteName = ref("");

const date = ref();

const defaultTime = new Date();
defaultTime.setHours(0, 0, 0, 0);
date.value = [defaultTime, null];

const responseData = ref<ReportPerDayResult>(null);
const servicingResponseData = ref<ServicingReportResult>(null);

const generate = () => {
    fetch("/util/report", {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            fromDate: DateTime.fromISO(date.value[0].toISOString()).toISO(),
            toDate: DateTime.fromISO(date.value[1]?.toISOString())?.toISO(),
            sites: Object.entries(selectedTreeTableValue.value)
                .filter((r) => r[1]?.checked)
                .map((r) => r[0])
        })
    })
        .then((response) => response.json())
        .then((data) => {
            responseData.value = data;
        });
};

const generate2 = () => {
    fetch("/util/report/servicing", {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            sites: Object.entries(selectedTreeTableValue.value)
                .filter((r) => r[1]?.checked)
                .map((r) => r[0])
        })
    })
        .then((response) => response.json())
        .then((data) => {
            servicingResponseData.value = data;
        });
};

const downloadReport = async (format: string) => {
    const response = await fetch("/util/report/download", {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            fromDate: DateTime.fromISO(date.value[0].toISOString()).toISO(),
            toDate: DateTime.fromISO(date.value[1]?.toISOString())?.toISO(),
            sites: Object.entries(selectedTreeTableValue.value)
                .filter((r) => r[1]?.checked)
                .map((r) => r[0]),
            format: format
        })
    });

    return response.json();
};

const formatDuration = (seconds: number) => {
    return Duration.fromObject({ seconds }).toFormat("hh:mm:ss");
};
</script>

<template>
    <Wrapper>
        <div class="grid grid-cols-12 gap-4" v-if="!responseData && !servicingResponseData">
            <div class="col-span-6 md:col-span-6 xl:col-span-3">
                <div @click="selectReport('util')" :class="{ 'selected-report': selectedReport == 'util' }" class="report-selector cursor-pointer card h-full flex flex-col items-center justify-center">
                    <i class="pi pi-chart-pie text-primary text-4xl mb-6"></i>
                    <span class="text-surface-900 dark:text-surface-0 text-2xl text-primary mb-6 font-medium">Utilisation Report</span>
                    <span class="text-surface-900 dark:text-surface-0 text-lg"></span>
                </div>
            </div>
            <div class="col-span-6 md:col-span-6 xl:col-span-3">
                <div @click="selectReport('servicing')" :class="{ 'selected-report': selectedReport == 'servicing' }" class="report-selector cursor-pointer card h-full flex flex-col items-center justify-center">
                    <i class="pi pi-chart-pie text-primary text-4xl mb-6"></i>
                    <span class="text-surface-900 dark:text-surface-0 text-2xl text-primary mb-6 font-medium">Servicing</span>
                    <span class="text-surface-900 dark:text-surface-0 text-lg"></span>
                </div>
            </div>
            <div class="col-span-6" v-if="selectedReport == 'util'">
                <p>Select your date range</p>
                <Calendar v-model="date" showIcon selectionMode="range" dateFormat="dd/mm/yy" :manualInput="false" />
                <br />
                <br />
                <Button @click="generate()" :disabled="Object.entries(selectedTreeTableValue).filter((r) => r[1]?.checked).length == 0">Generate</Button>
            </div>
            <div class="col-span-6" v-if="selectedReport == 'servicing'">
                <Button @click="generate2()" :disabled="Object.entries(selectedTreeTableValue).filter((r) => r[1]?.checked).length == 0">Generate</Button>
            </div>
        </div>

        <UtilisationReportResults v-if="responseData" :results="responseData" :download="downloadReport" />
        <ServicingReportResultPage v-if="servicingResponseData" :results="servicingResponseData" />

        <br />
        <div v-if="responseData">
            <h2 class="font-bold text-3xl m-4">Daily Breakdown</h2>
            <div v-for="item in responseData.dates">
                <DataTable :value="item.rows" tableStyle="width: 100%" showGridlines columnResizeMode="fit" scrollable>
                    <template #header>
                        <div class="flex flex-wrap items-center justify-between gap-2">
                            <span class="text-xl text-surface-900 dark:text-surface-0 font-bold">{{ DateTime.fromISO(item.date).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY) }}</span>
                        </div>
                    </template>
                    <Column field="assetName" header="Name"></Column>
                    <Column header="Ignition Time">
                        <template #body="slotProps"> {{ Duration.fromObject({ seconds: slotProps.data.totalSeconds }).toFormat("hH m") }}M </template>
                    </Column>
                    <Column header="Utilisation">
                        <template #body="slotProps">
                            <UtilisationIndicator :percentage="slotProps.data.totalPercentage" />
                            {{ slotProps.data.totalPercentage.toFixed(2) }}%
                        </template>
                    </Column>
                    <template #footer> Total duration {{ Duration.fromObject({ seconds: item.grandTotalSeconds }).toFormat("hH m") }}M </template>
                </DataTable>
                <br />
                <br />
            </div>
        </div>

        <div v-if="(selectedReport == 'util' || selectedReport == 'servicing') && !responseData && !servicingResponseData">
            <TreeTable :value="containerNodes" selectionMode="checkbox" v-model:selectionKeys="selectedTreeTableValue">
                <Column field="name" header="Name" :expander="true"></Column>
                <Column field="asset_count" header="Assets"></Column>
            </TreeTable>
        </div>
    </Wrapper>
</template>

<style scoped lang="scss">
.report-selector {
    background: #523180;
}

.selected-report {
    background: #3c2660;
}
</style>
