import { defineStore } from "pinia";
import { ref } from "vue";
import type { AssetTableItem, Coordinator } from "@/db";
import { until } from "@vueuse/core";
import type { Feature, FeatureCollection, Geometry } from "geojson";

export const useGeoJSONStore = defineStore("geojson-pos", () => {
    // TODO: Convert into ShallowRef. Removing the top level ref seems to break reactivity.
    const allPositions = new Map<string, Coordinator>();
    const ready = ref(false);

    function init(current: Coordinator[]) {
        for (const r of current) {
            allPositions.set(r.id, r);
        }

        ready.value = true;
    }

    async function waitForReady(): Promise<void> {
        await until(ready).toBe(true);
        return;
    }

    function add(id: string, item: Coordinator): void {
        allPositions.set(id, item);
    }

    function update(id: string, item: Partial<Coordinator>): boolean {
        if (!allPositions.has(id)) {
            console.warn(`id: ${id} IS NOT KNOWN. Received update though?`);
            return false;
        }
        const currentItem = allPositions.get(id);
        //const updatedItem = { ...currentItem, ...item };
        const itemPlain = item as { k: any };

        if ("longitude" in itemPlain) {
            currentItem.longitude = item.longitude;
        }

        if ("latitude" in itemPlain) {
            currentItem.latitude = item.latitude;
        }

        if ("angle" in itemPlain) {
            currentItem.angle = item.angle;
        }

        if ("speed" in itemPlain) {
            currentItem.speed = item.speed;
        }

        if ("timestamp" in itemPlain) {
            currentItem.timestamp = item.timestamp;
        }

        return true;
    }

    function format(m: Map<string, AssetTableItem>): FeatureCollection {
        return {
            type: "FeatureCollection",
            features: Array.from(allPositions.entries()).map((k) => {
                const asset = m.get(k[1].serial);
                return {
                    type: "Feature",
                    id: k[1].id,
                    geometry: {
                        type: "Point",
                        coordinates: [parseFloat(k[1].longitude), parseFloat(k[1].latitude)]
                    } as Geometry,
                    properties: {
                        name: asset?.name ?? "",
                        serial: k[1].serial,
                        rotation: k[1].angle,
                        speed: k[1].speed,
                        type: asset?.type
                    }
                } as Feature;
            })
        };
    }

    return { allPositions, ready, init, add, update, waitForReady, format };
});
