<script setup lang="ts">
import { computed, inject, ref } from "vue";
import { Link, router } from "@inertiajs/vue3";
import { useToast } from "primevue/usetoast";
import { Config } from "@/utils/build";

const configuration = inject(Config);

const props = defineProps<{
    is_successful: boolean;
    error_message?: string;
    email?: string;
}>();

const password = ref("");
const confirmedPassword = ref("");

const toast = useToast();
const formLoading = ref(false);
const pwErr = ref("");

const hasError = computed(() => props.is_successful === false && props.error_message);

const submitCode = async (e: Event) => {
    e.preventDefault();

    if (password.value != confirmedPassword.value) {
        pwErr.value = "Passwords do not match";
        return;
    }

    formLoading.value = true;

    const rawResponse = await fetch("", {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            password: password.value,
            confirmed_password: confirmedPassword.value
        })
    });

    formLoading.value = false;
    if (rawResponse.ok) {
        router.visit("/login?pwupdated=1");
    } else {
        toast.add({ severity: "error", detail: "Registration could not be submitted" });
    }
};
</script>

<template>
    <Toast />

    <div class="px-8 min-h-screen flex justify-center items-center">
        <div class="border w-40rem border-surface bg-surface-0 dark:bg-surface-900 rounded-border py-16 px-6 md:px-16 z-10">
            <img class="mb-11" :src="configuration.LogoURL" :alt="configuration.PlatformProvider" />

            <div class="pb-2">
                <Link class="text-surface-200 font-bold cursor-pointer hover:text-primary cursor-pointer ml-auto transition-colors duration-300" href="/">&lt; Sign in page </Link>
            </div>

            <p v-if="props.is_successful">You have been invited to create an account.</p>

            <Message v-if="hasError" :closable="false" severity="error" class="mt-6">{{ props.error_message }}</Message>
            <Message v-if="pwErr" severity="error" class="mt-6">{{ pwErr }}</Message>

            <div class="flex flex-col" v-if="!hasError">
                <form @submit="submitCode">
                    <div class="text-surface-900 dark:text-surface-0 text-xl font-bold mb-2">Enter a password</div>
                    <span class="text-surface-600 dark:text-surface-200 font-medium"></span>

                    <span class="font-mono p-4 block"> <span class="pi pi-user"></span> {{ props.email }} </span>

                    <InputText :disabled="!props.is_successful" id="password" v-model="password" :class="{ 'p-invalid': false }" type="password" class="w-full md:w-[25rem] mb-3" placeholder="New Password" />
                    <InputText :disabled="!props.is_successful" id="confirm_password" v-model="confirmedPassword" :class="{ 'p-invalid': false }" type="password" class="w-full md:w-[25rem]" placeholder="Confirm Password" />

                    <Button :disabled="!props.is_successful" label="Create account" :loading="formLoading" class="w-full mt-8" type="submit"></Button>
                </form>
            </div>
        </div>
    </div>
</template>
