<script setup lang="ts">
import { ref, onUnmounted, inject, onMounted, toRef, watchEffect, nextTick } from "vue";
import { mapSymbol } from "@/types";
import { type LngLatLike, Popup } from "maplibre-gl";

const props = defineProps<{
    coords: LngLatLike;
    closable?: boolean;
}>();

const coords = toRef(props, "coords");
const popup = new Popup({
    offset: 5,
    closeButton: props.closable,
    maxWidth: "none"
});
const mounted = ref(false);
const pls = ref(null);

const map = inject(mapSymbol)!;

onUnmounted(() => {
    popup.remove();
    //yrse
});

onMounted(async () => {
    mounted.value = true;
});

watchEffect(async () => {
    popup.setLngLat(coords.value);
    if (!popup.isOpen()) {
        //	popup.isOpen()
    }

    popup.addTo(map.value);
    await nextTick(() => {
        popup.setHTML(pls.value.innerHTML);
    });
});
</script>

<template>
    <div ref="pls" style="position: absolute; display: none">
        <div class="wrapper">
            <slot />
        </div>
    </div>
</template>

<style lang="scss" scoped>
/*Hackjob to force Primevue components into a non-V-DOM context*/
.p-message-enter-from {
    opacity: 100 !important;
}

.s-hed {
    color: rgba(255, 255, 255, 0.7) !important;
}
.maplibregl-popup-content > * {
    color: #fff;
}

.border-top-1 {
    border-color: #0b213f;
}

.wrapper {
    font-size: 1rem;
    padding: 0 20px 0 20px !important;
    color: #fff !important;
}

#seviceprogress.p-progressbar {
    height: 12px;
}
.loc {
    color: rgba(255, 255, 255, 0.5);
}
.p-tag.p-tag-success {
    color: #fff !important;
    background-color: #22c55e !important;
}
</style>
