<script setup lang="ts">
import type { TreeNode } from "primevue/treenode";
import { type Ref, watchEffect } from "vue";
import { inject, ref, shallowRef, toRaw, watch } from "vue";
import { Config } from "@/utils/build";
import type { TreeSelectProps } from "primevue/treeselect";
import TreeSelect from "primevue/treeselect";

const configuration = inject(Config);

interface ContainerItemV2 {
    id: string;
    name: string;
    asset_count: number;
    parent_container_id?: string;
}

type NewContainerlist = { [key: string]: ContainerItemV2 };

interface ContainerItem {
    id: string;
    friendlyName: string;
    relativePath: string;
    fullPath: string;
    pathName: string;
}

const props = defineProps<{
    containers?: NewContainerlist;
    withDomain?: boolean;
    single?: boolean;
    scalar?: boolean;
    placeholder?: string;
    initialSelect?: string[];
}>();

const model = defineModel();

const emit = defineEmits<{
    (e: "filtered", values: string[]): void;
}>();

const placeholder: Ref<string> = ref("Filter by container");
const selectionMode: Ref<TreeSelectProps["selectionMode"]> = ref("checkbox");
const selectedValue = shallowRef(null);

watchEffect(() => {
    if (!props.scalar) {
        model.value = selectedValue.value;
        return;
    }

    if (!selectedValue.value) {
        model.value = selectedValue.value;
        return;
    }

    if (props.single) {
        model.value = Object.keys(selectedValue.value)[0] ?? "";
        return;
    }

    model.value = Object.keys(selectedValue.value);
});

watch(
    () => props.single,
    (newVal) => {
        selectionMode.value = newVal ? "single" : "checkbox";
    },
    { immediate: true }
);

if (props.initialSelect?.length) {
    selectedValue.value = props.initialSelect.reduce((acc, curr, index) => {
        acc[curr] = {
            checked: true
        };
        return acc;
    }, {});
}

watch(
    () => props.placeholder,
    (newVal) => {
        if (newVal) {
            placeholder.value = props.placeholder;
            return;
        }
    },
    { immediate: true }
);

// relativePath, id
const siteTree = ref<TreeNode[]>([]);

const loadContainers = () => {
    // Hacky derefence.
    const cNewCopy = JSON.parse(JSON.stringify(toRaw(props.containers)));

    //const nodes = Array<TreeNode>()
    const nodes = new Map<string, TreeNode>();
    let rootNode: TreeNode;

    for (const cntnID of Object.keys(cNewCopy)) {
        if (!cNewCopy[cntnID].hasOwnProperty("parent_container_id") || cNewCopy[cntnID].parent_container_id == "") {
            rootNode = {
                key: cNewCopy[cntnID].id,
                data: {
                    name: cNewCopy[cntnID].name,
                    size: "~",
                    actions: ""
                },
                label: cNewCopy[cntnID].name,
                children: []
            } as TreeNode;
            nodes.set(cNewCopy[cntnID].id, rootNode);
            delete cNewCopy[cntnID];
        }
    }

    let i = 0;

    while (Object.values(cNewCopy).length > 0) {
        if (Object.values(cNewCopy).length == 0) {
            break;
        }

        i++;
        if (i > 1000) {
            console.log("unsafe break");
            break;
        }

        for (const cntnID of Object.keys(cNewCopy)) {
            const parent = nodes.get(cNewCopy[cntnID].parent_container_id);
            if (parent) {
                const newNode = {
                    key: cNewCopy[cntnID].id,
                    data: {
                        name: cNewCopy[cntnID].name,
                        size: "~",
                        actions: ""
                    },
                    label: cNewCopy[cntnID].name,
                    children: []
                } as TreeNode;

                nodes.set(cntnID, newNode);
                parent.children.push(newNode);
                delete cNewCopy[cntnID];
            }
        }
    }

    if (toRaw(props.withDomain)) {
        return [rootNode];
    }

    return (
        rootNode?.children || [
            {
                key: "",
                data: {},
                label: "",
                children: []
            }
        ]
    );
};

watch(selectedValue, () => {
    let filterKeys: string[] = [];
    // TODO: FILTER OUT PARTIALS
    for (const [aa, bb] of Object.entries(toRaw(selectedValue.value))) {
        if (bb?.["checked"] && !bb?.["partialChecked"]) {
            filterKeys.push(aa);
        }
    }
    emit("filtered", filterKeys);
});

(async () => {
    siteTree.value = loadContainers();
})();
</script>

<template>
    <TreeSelect v-model="selectedValue" :selection-mode="selectionMode" :options="siteTree" :placeholder="placeholder" :class="['w-full']" />
</template>

<style>
.p-treeselect-panel {
    z-index: 999999 !important;
}
</style>
