import { defineStore } from "pinia";
import { ref, shallowRef } from "vue";
import type { Ref } from "vue";
import type { Coordinator } from "@/db";
import { until } from "@vueuse/core";

export const useLatestPositionsStore = defineStore("latest-positions", () => {
    // TODO: Convert into ShallowRef. Removing the top level ref seems to break reactivity.
    const allPositions = ref(new Map<string, Ref<Coordinator>>());
    const ready = ref(false);

    function init(current: Coordinator[]) {
        for (const r of current) {
            allPositions.value.set(r.id, shallowRef(r));
        }

        ready.value = true;
    }

    async function waitForReady(): Promise<void> {
        await until(ready).toBe(true);
        return;
    }

    function add(id: string, item: Coordinator): void {
        allPositions.value.set(id, shallowRef(item));
    }

    function update(id: string, item: Partial<Coordinator>): boolean {
        if (!allPositions.value.has(id)) {
            console.warn(`id: ${id} IS NOT KNOWN. Received update though?`);
            return false;
        }
        const currentItem = allPositions.value.get(id);
        //const updatedItem = { ...currentItem, ...item };
        const itemPlain = item as { k: any };

        if ("longitude" in itemPlain) {
            currentItem.value.longitude = item.longitude;
        }

        if ("latitude" in itemPlain) {
            currentItem.value.latitude = item.latitude;
        }

        if ("angle" in itemPlain) {
            currentItem.value.angle = item.angle;
        }

        if ("speed" in itemPlain) {
            currentItem.value.speed = item.speed;
        }

        if ("timestamp" in itemPlain) {
            currentItem.value.timestamp = item.timestamp;
        }

        return true;
    }

    return { allPositions, ready, init, add, update, waitForReady };
});
