<script setup lang="ts">
import { computed, ref, watchEffect } from "vue";
import AppSubMenu from "./AppSubMenu.vue";
import { useLayout } from "@/layout/composables/layout";
import type { MenuItem } from "primevue/menuitem";
import { useBreakpoints } from "@vueuse/core";
import { breakpointsConfig } from "@/utils/breakpoints";

const breakpoints = useBreakpoints(breakpointsConfig);
const isDesktop = ref(true);

const { layoutConfig } = useLayout();

const model = ref<MenuItem[]>([
    //{
    //label: "Navigation",
    //items: [
    {
        label: "Map",
        icon: "pi pi-fw pi-map-marker",
        url: "/map",
        root: true
    },
    {
        label: "Assets",
        icon: "pi pi-fw pi-car",
        url: "/assets"
    },
    {
        label: "Servicing",
        icon: "pi pi-fw pi-calendar-times",
        url: "/servicing/overview"
    },
    /*
    {
      label: "Trackers",
      icon: "pi pi-th-large",
      items: [
        {
          label: "Location",
          icon: "pi pi-fw pi-compass",
          items: [
            {
              label: "List",
              icon: "pi pi-fw pi-book",
              to: "/"
            },
            {
              label: "Vehicles",
              icon: "pi pi-fw pi-car",
              to: "/"
            },
            {
              label: "Generators",
              icon: "pi pi-fw pi-bolt",
              to: "/"
            },
            {
              label: "Towers",
              icon: "pi pi-fw pi-building",
              to: "/"
            }
          ]
        },
        {
          label: "Reporting",
          icon: "pi pi-chart-line pi-calendar",
          to: "/report"
        }
      ]
    },
     */
    {
        label: "Reports",
        icon: "pi pi-fw pi-chart-pie",
        url: "/reports"
    },
    {
        label: "Setup",
        icon: "pi pi-fw pi-globe",
        items: [
            {
                label: "Sites",
                icon: "pi pi-fw pi-sitemap",
                url: "/management/sites"
            },
            {
                label: "Users",
                icon: "pi pi-fw pi-users",
                url: "/management/users"
            },
            {
                label: "Hardware",
                icon: "pi pi-fw pi-globe",
                url: "/management/trackers"
            }
            /*{
						label: "Connector",
						icon: "pi pi-fw pi-globe",
						to: "/management/trackers"
					},*/
        ]
    }
]);

watchEffect(() => {
    isDesktop.value = breakpoints.greater("lg").value ? true : false;
});

const menuModel = computed<MenuItem[]>(() =>
    isDesktop.value && layoutConfig.menuMode == "horizontal"
        ? model.value
        : [
              {
                  label: "Navigation",
                  items: model.value
              }
          ]
);
</script>

<template>
    <AppSubMenu :model="menuModel" />
</template>

<style lang="scss" scoped></style>
