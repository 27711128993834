<script lang="ts" setup>
import { computed, inject, onBeforeMount, onBeforeUnmount, onMounted, ref, toRef, watch, watchEffect } from "vue";
import { mapSymbol } from "@/types";
import { useGeoJSONStore } from "@/store/geojson";
import type { Position } from "geojson";
import type { GeoJSONSource, LngLatBoundsLike, LngLatLike } from "maplibre-gl";
import { bbox, circle } from "@turf/turf";
import MapPopupGeneric from "@/components/MapPopupGeneric.vue";
import { DateTime } from "luxon";
import type { AssetType } from "@/db";
import { toAbsHumanDuration } from "../../utils/time";
import { router } from "@inertiajs/vue3";
import { Link } from "@inertiajs/vue3";

interface TripResponse {
    Path: { coordinates: Array<LngLatLike>; distance_meters: number };
    TripFrom: string;
    TripUntil: string;
}

const props = defineProps<{
    trip: TripResponse;
    assetType: AssetType;
    ttid: string;
}>();

const trip = toRef(props, "trip");
const map = inject(mapSymbol);
const tripPath: { path: Array<LngLatLike> } = { path: [] };

const tripFrom = computed(() => {
    return DateTime.fromISO(trip.value.TripFrom);
});

const tripUntil = computed(() => {
    return DateTime.fromISO(trip.value.TripUntil);
});

watchEffect(
    async () => {
        if (!trip.value) {
            // Trip deselected?
        } else {
            tripPath.path = trip.value.Path.coordinates;
            const src = map.value.getSource("utilisation-area") as GeoJSONSource;
            if (!src) {
                return;
            }

            var utilisationRadius = circle(trip.value.Path.coordinates[0] as any, 0.05, { steps: 64, units: "kilometers" });

            src.updateData({
                update: [
                    {
                        id: "main",
                        newGeometry: {
                            type: utilisationRadius.geometry.type,
                            coordinates: utilisationRadius.geometry.coordinates as any
                        }
                    }
                ]
            });

            map.value.setLayoutProperty("location-radius-fill", "visibility", "visible");

            var tripBounds = bbox(await src.getData()) as LngLatBoundsLike;
            map.value.fitBounds(tripBounds, {
                screenSpeed: 4,
                maxZoom: 16
            });

            strongestCoords.value = tripPath.path[0] as LngLatLike;
        }
    },
    { flush: "post" }
);

onBeforeUnmount(() => {
    map.value.removeLayer("placement");
    map.value.removeLayer("location-radius-fill");
    map.value.removeSource("utilisation-area");
});

const strongestCoords = ref<LngLatLike>();

onBeforeMount(async () => {
    map.value.addSource("utilisation-area", {
        type: "geojson",
        data: {
            type: "Feature",
            properties: {},
            id: "main",
            geometry: {
                type: "LineString",
                coordinates: [] as Position[]
            }
        }
    });

    map.value.addLayer({
        id: "placement",
        source: "utilisation-area",
        type: "line",
        paint: {
            "line-width": 4
        }
    });

    map.value.addLayer({
        id: "location-radius-fill",
        type: "fill",
        source: "utilisation-area",
        layout: {
            visibility: "none"
        },
        paint: {
            "fill-color": "#8CCFFF",
            "fill-opacity": 0.5
        }
    });
});

const exitTrip = () => {
    router.visit(`/map/`, { preserveState: true });
};
</script>

<template>
    <MapPopupGeneric v-if="strongestCoords" :coords="strongestCoords">
        <table class="w-full" :style="{ borderCollapse: 'collapse', tableLayout: 'auto' }">
            <thead>
                <tr>
                    <th class="text-left font-semibold py-2 border-b border-surface whitespace-nowrap">Started</th>
                    <th class="text-right font-semibold py-2 border-b border-surface whitespace-nowrap px-4">
                        {{ tripFrom.toLocaleString(DateTime.DATETIME_MED) }}
                    </th>
                </tr>
                <tr>
                    <th class="text-left font-semibold py-2 border-b border-surface whitespace-nowrap">Finished</th>
                    <th class="text-right font-semibold py-2 border-b border-surface whitespace-nowrap px-4">
                        {{ tripUntil.toLocaleString(DateTime.DATETIME_MED) }}
                    </th>
                </tr>
                <tr>
                    <th class="text-left font-semibold py-2 border-b border-surface whitespace-nowrap">Duration</th>
                    <th class="text-right font-semibold py-2 border-b border-surface whitespace-nowrap px-4">
                        {{ toAbsHumanDuration(tripFrom, tripUntil) }}
                    </th>
                </tr>
            </thead>
        </table>
    </MapPopupGeneric>
</template>
