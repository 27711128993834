<script setup>
import AppLogo from "@/components/AppLogo.vue";
import ProfileButton from "@/components/ProfileButton.vue";
import { useLayout } from "@/layout/composables/layout";

const { setStaticMenuMobile } = useLayout();
</script>

<template>
    <div class="layout-topbar mb-0 w-full py-2 items-center px-8 lg:hidden" @keydown.esc="setStaticMenuMobile">
        <div class="topbar-start">
            <Button type="button" icon="pi pi-bars" class="!bg-blue-400 p-trigger !rounded-full h-[38px] !border-none" @click.stop="setStaticMenuMobile" />
        </div>

        <!--<Button icon="pi pi-box" class="flex-1" label="Add new tracked asset"></Button>
                <li class="topbar-search">
                    <span class="p-input-icon-left">

                        <i class="pi pi-search"></i>
                        <InputText type="text" placeholder="Search for asset" class="w-48 sm:w-48" />
                    </span>
                </li>
                -->

        <AppLogo />

        <ProfileButton />
    </div>
</template>

<style lang="scss" scoped>
.layout-topbar {
    background-color: var(--menu-bg);
}
</style>
