import type { Feature } from "geojson";

export class Fea {
    private indexer = new Map<string, Feature>();
    private features = new Array<Feature>();

    init(items: Array<Feature>): void {
        this.indexer.clear();
        this.features = [];

        console.log("posRef init", items.length, "items");
        for (const i of items) {
            const f = i;
            this.indexer.set(i.id as unknown as string, i);
            this.features.push(f);
        }
    }

    get(id: string): Feature {
        return this.indexer.get(id);
    }

    list(): Array<Feature> {
        return this.features;
    }
}
