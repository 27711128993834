<script setup lang="ts">
import { inject, ref } from "vue";
import { useAuthStore } from "./../../store/auth";
import { Link } from "@inertiajs/vue3";
import { useToast } from "primevue/usetoast";
import { Config } from "@/utils/build";

const configuration = inject(Config);

const toast = useToast();

const userStore = useAuthStore();
//import router from '@/router/';
const router = {
    currentRoute: {
        value: {
            query: {
                goto: ""
            }
        }
    },
    push: async (obj: any) => {}
};

const email = ref("");
const password = ref("");
const loading = ref(false);
const loginError = ref("");

let urlParams = new URLSearchParams(window.location.search);

if (urlParams.has("pwupdated")) {
    setTimeout(() => toast.add({ severity: "info", detail: "Your new password has been set." }), 500);
}

if (urlParams.has("loggedout")) {
    setTimeout(
        () =>
            toast.add({
                severity: "warn",
                summary: "You've been logged out."
            }),
        500
    );
}

(async () => {
    await userStore.session();
    if (userStore.userData) {
        window.location.href = "map";
    }
})();

const navigateToDashboard = async (e: Event) => {
    e.preventDefault();

    loading.value = true;
    const yers = await userStore.login(email.value, password.value);

    loading.value = false;
    if (yers.ok) {
        window.location.href = "map";

        // Refresh session for store state
        //await userStore.session()
        //if ('goto' in route.query) {
        //     await router.push({ path: route.query?.goto as string });
        // } else {
        //     await router.push({ name: 'overview' });
        //}
    } else {
        if (yers?.content?.error?.code == 401) {
            loginError.value = yers?.content?.error?.reason;
        } else {
            const loginResponseMessage = yers?.content?.ui?.messages?.[0];

            if (loginResponseMessage?.type === "error") {
                loginError.value = loginResponseMessage.text;
            }
        }
    }
};
</script>

<template>
    <Toast />
    <div class="px-8 min-h-screen flex justify-center items-center">
        <div class="border w-40rem border-surface bg-surface-0 dark:bg-surface-900 rounded-border py-16 px-6 md:px-16 z-10">
            <img class="mb-11" :src="configuration.LogoURL" :alt="configuration.PlatformProvider" />

            <div class="mb-6">
                <div class="text-surface-900 dark:text-surface-0 text-xl font-bold mb-2">Login to your account</div>
                <span class="text-surface-600 dark:text-surface-200 font-medium">Please enter your details</span>
            </div>

            <div class="flex flex-col">
                <form @submit="navigateToDashboard">
                    <IconField class="mb-3">
                        <InputIcon class="pi pi-envelope" />
                        <InputText autofocus id="email" v-model="email" :class="{ 'p-invalid': loginError }" type="email" class="w-full md:w-[25rem]" placeholder="Email Address" />
                    </IconField>
                    <IconField class="my-3">
                        <InputIcon class="pi pi-lock" />
                        <InputText id="password" v-model="password" autocomplete="current-password" :class="{ 'p-invalid': loginError }" type="password" class="w-full md:w-[25rem]" placeholder="Password" />
                    </IconField>

                    <div class="mb-6 flex flex-wrap gap-4">
                        <!--<div>
                            <Checkbox name="checkbox" v-model="rememberMe" binary class="mr-2"></Checkbox>
                            <label htmlFor="checkbox" class="text-surface-900 dark:text-surface-0 font-medium mr-20"> Remember Me </label>
                        </div>
                        -->
                        <Link class="text-surface-600 dark:text-surface-200 cursor-pointer hover:text-primary cursor-pointer transition-colors duration-300" href="/recover">Forgot password</Link>
                    </div>

                    <div v-if="loginError" class="my-8 whitespace-normal" style="width: 100%">
                        <InlineMessage severity="error">{{ loginError }}</InlineMessage>
                    </div>

                    <Button :loading="loading" type="submit" label="Log In" class="w-full"></Button>
                </form>
            </div>
        </div>
    </div>
</template>

<style>
.w-40rem {
    width: 460px;
}
</style>
