<script setup lang="ts">
import { inject, ref } from "vue";
import { Link } from "@inertiajs/vue3";
import { useToast } from "primevue/usetoast";
import { router } from "@inertiajs/vue3";
import { Config } from "@/utils/build";

const { PlatformProvider } = inject(Config);

const password = ref("");
const email = ref("");

const toast = useToast();
const formLoading = ref(false);

/*
const OryCfg = new Configuration({
  baseOptions: {
    withCredentials: true,
  }
});

if (!OryCfg.basePath) {
  const cfg = inject(Config);
  OryCfg.basePath = cfg.OryBase
}

const Ory = new FrontendApi(OryCfg)

const eggs = await Ory.createBrowserRecoveryFlow({

})
*/

let urlParams = new URLSearchParams(window.location.search);

const withToken = ref(false);
const token = ref(urlParams.has("token") ? decodeURIComponent(urlParams.get("token")) : "");
const payload = ref("");

if (urlParams.has("token")) {
    withToken.value = true;
    payload.value = JSON.parse(atob(token.value.split(".")[1]));
}

const submitCode = async (e) => {
    e.preventDefault();
    formLoading.value = true;

    const rawResponse = await fetch("", {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            token: token.value,
            newPassword: password.value
        })
    });

    formLoading.value = false;
    if (rawResponse.ok) {
        router.visit("/login?pwupdated=1");
    } else {
        toast.add({ severity: "error", detail: "Password reset could not be submitted" });
    }
};

const submitForgottenPassword = async (e) => {
    e.preventDefault();
    formLoading.value = true;

    const rawResponse = await fetch("", {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            emailAddress: email.value
        })
    });
    formLoading.value = false;

    if (rawResponse.ok) {
        email.value = "";
        toast.add({ severity: "info", detail: "Password reset email has been sent", summary: "Check your email inbox." });
    } else {
        toast.add({ severity: "error", detail: "Password reset could not be submitted" });
    }
};
</script>

<template>
    <Toast />
    <div class="px-8 min-h-screen flex justify-center items-center">
        <div class="border w-40rem border-surface bg-surface-0 dark:bg-surface-900 rounded-border py-16 px-6 md:px-16 z-10">
            <div class="py-4">
                <Link class="text-surface-600 dark:text-surface-200 cursor-pointer hover:text-primary cursor-pointer ml-auto transition-colors duration-300" href="/login">&lt; Back</Link>
            </div>

            <div class="flex flex-col">
                <form @submit="submitCode" v-if="withToken">
                    <div class="mb-6">
                        <div class="text-surface-900 dark:text-surface-0 text-xl font-bold mb-2">Enter a new password</div>
                        <span class="text-surface-600 dark:text-surface-200 font-medium"></span>
                    </div>

                    <span class="p-input-icon-left w-full mb-6">
                        {{ payload.sub }}
                    </span>

                    <span class="p-input-icon-left w-full mb-6">
                        <InputText id="password" v-model="password" :class="{ 'p-invalid': false }" type="password" class="w-full md:w-[25rem]" placeholder="New Password" />
                    </span>

                    <Button label="Submit" :loading="formLoading" class="w-full mb-6" type="submit"></Button>

                    <i>This link is only valid for 3 hours.</i>
                </form>
                <form @submit="submitForgottenPassword" v-if="!withToken">
                    <div class="mb-6">
                        <div class="text-surface-900 dark:text-surface-0 text-xl font-bold mb-2">Forgot your password?</div>
                        <span class="text-surface-600 dark:text-surface-200 font-medium">Enter the email address associated with your {{ PlatformProvider }} account, and we'll email you a link to reset your password.</span>
                    </div>

                    <span class="p-input-icon-left w-full mb-6">
                        <i class="pi pi-envelope"></i>
                        <InputText id="email" v-model="email" :class="{ 'p-invalid': false }" type="email" class="w-full md:w-[25rem]" placeholder="Email" />
                    </span>
                    <!--
            <div v-if="1 == 2">
              <InlineMessage severity="info">An email containing a recovery code has been sent to the email address you provided. If you have not received an email, check the spelling of the address and make sure to use the address you registered with.</InlineMessage>
            </div>
            -->
                    <Button label="Submit" :loading="formLoading" class="w-full mb-6" type="submit"></Button>
                    <!--<Button label="Resend Code" class="w-full mb-6" @click="recoverPassword"></Button>-->
                </form>
            </div>
        </div>
    </div>
</template>
