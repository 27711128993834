import type { Plugin, InjectionKey } from "vue";
import SharedWorkerPolyfill, { SharedWorkerSupported } from "@/worker/polyfill";

export interface Synchroniser {
    resync(): void;
}

export const SyncWorker = Symbol() as InjectionKey<Synchroniser>;

export const SyncPlugin: Plugin = {
    install: (app, options) => {
        let worker: SharedWorkerPolyfill;

        if (SharedWorkerSupported) {
            worker = new SharedWorkerPolyfill(new SharedWorker(new URL("./../worker.ts", import.meta.url), { name: "position-sync", type: "module" }));
        } else {
            worker = new SharedWorkerPolyfill(new Worker(new URL("./../worker.ts", import.meta.url), { name: "position-sync", type: "module" }));
        }

        worker.onmessage = (e: MessageEvent<any>) => {
            console.log(e);
        };

        const wrappedWorker = {
            resync: () => worker.postMessage("resync")
        } as Synchroniser;

        app.provide(SyncWorker, wrappedWorker);
    }
};
