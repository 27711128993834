//import { Configuration, FrontendApi } from "@ory/client";
//import type { Session, LogoutFlow } from "@ory/client";
import type { Plugin, InjectionKey, Ref } from "vue";
import type { AxiosResponse } from "axios";
import { ref } from "vue";
//import router from './../router';
import { useAuthStore } from "@/store/auth";

/*export const oryBasePath = import.meta.env.VITE_ORY_KRATOS_URL;

export const Ory = new FrontendApi(
  new Configuration({
    basePath: oryBasePath,
    baseOptions: {
      withCredentials: true,
    }
  }),
)*/

//export const $refresh: InjectionKey<Ref<Refresher>> = Symbol('$refresh');

export const OryPlugin: Plugin = {
    async install(app) {
        // can now be used with inject($ory)
        //app.provide($ory, Ory);
        //let sessh = ref<Session|false>(false)
        /*const sessionRefresher = async () => {
      await Ory.toSession()
        .then(({ data }) => {
          //sessh.value = data
        })
        .catch(async () => {
          //@ts-ignore
          console.log(app.$route)
          // @ts-ignore
          //await router.push({ name: 'login' });

          console.log('[Ory] User has no session.');
        });
    }*/
        //app.config.globalProperties.$session = sessh
        /*Promise.all([
      // get the logout url
      Ory.createBrowserLogoutFlow().catch(
        () =>
          ({
            data: {
              logout_url: '',
            },
          } as AxiosResponse<LogoutFlow>)
      ),
    ]).then(([{ data: logoutData }]) => {
      app.provide($ory_urls, {
        logoutUrl: logoutData.logout_url,
      });
    });*/
    }
};
