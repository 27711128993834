<script setup lang="ts">
import { computed, watch, ref, onBeforeUnmount, onMounted, shallowRef, toRef, provide } from "vue";
import AppTopbar from "./AppTopbar.vue";
import AppSidebar from "./AppSidebar.vue";
import AppProfileSidebar from "./AppProfileSidebar.vue";
import AppBreadCrumb from "./AppBreadcrumb.vue";
import { useLayout } from "@/layout/composables/layout";
import { mapSymbol } from "@/types";
import { Map as MapboxMap } from "maplibre-gl";

const { layoutConfig, layoutState, watchSidebarActive, unbindOutsideClickListener } = useLayout();

const props = defineProps<{
    menuMode?: string;
    isPadded?: boolean;
}>();

const outsideClickListener = ref(null);
const sidebarRef = shallowRef(null);
const topbarRef = ref(null);
const currentMenuMode = toRef(props, "menuMode");

watch(
    currentMenuMode,
    (newV) => {
        //layoutConfig.menuMode = newV ? newV : 'static';
    },
    { immediate: true }
);

onMounted(() => {
    watchSidebarActive();
});

onBeforeUnmount(() => {
    unbindOutsideClickListener();
});

const containerClass = computed(() => {
    return {
        "layout-light": !layoutConfig.darkTheme,
        "layout-dark": layoutConfig.darkTheme,
        "layout-colorscheme-menu": layoutConfig.menuTheme === "colorScheme",
        "layout-primarycolor-menu": layoutConfig.menuTheme === "primaryColor",
        "layout-transparent-menu": layoutConfig.menuTheme === "transparent",
        "layout-overlay": layoutConfig.menuMode === "overlay",
        "layout-static": layoutConfig.menuMode === "static",
        "layout-slim": layoutConfig.menuMode === "slim",
        "layout-slim-plus": layoutConfig.menuMode === "slim-plus",
        "layout-horizontal": layoutConfig.menuMode === "horizontal",
        "layout-reveal": layoutConfig.menuMode === "reveal",
        "layout-drawer": layoutConfig.menuMode === "drawer",
        "layout-static-inactive": layoutState.staticMenuDesktopInactive && layoutConfig.menuMode === "static",
        "layout-overlay-active": layoutState.overlayMenuActive,
        "layout-mobile-active": layoutState.staticMenuMobileActive,
        "layout-sidebar-active": layoutState.sidebarActive,
        "layout-sidebar-anchored": layoutState.anchored
    };
});
</script>

<template>
    <div :class="['layout-container', { ...containerClass }]" class="h-screen flex flex-col">
        <AppSidebar />
        <AppTopbar />

        <div class="layout-content-wrapper-full h-full relative">
            <div class="layout-content max-lg:flex max-lg:flex-col-reverse !w-full h-full relative">
                <slot></slot>
            </div>
        </div>

        <AppProfileSidebar />
        <Toast></Toast>
        <div class="layout-mask"></div>
    </div>
</template>

<style lang="scss"></style>
