export const breakpointsConfig = {
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
    "2xl": "1920px"
};

if (typeof module !== "undefined" && module.exports) {
    module.exports = breakpointsConfig;
}
