import { definePreset } from "@primevue/themes";
import Lara from "@primevue/themes/lara";

const theme = definePreset(Lara, {
    semantic: {
        primary: {
            50: "#f5f3ff",
            100: "#ede9fe",
            200: "#ddd6fe",
            300: "#c4b5fd",
            400: "#a78bfa",
            500: "#8b5cf6",
            600: "#7c3aed",
            700: "#6d28d9",
            800: "#5b21b6",
            900: "#4c1d95",
            950: "#2e1065"
        },
        colorScheme: {
            dark: {
                surface: {
                    0: "{slate.0}",
                    50: "{slate.50}",
                    100: "{slate.100}",
                    200: "{slate.200}",
                    300: "{slate.300}",
                    400: "{slate.400}",
                    500: "{slate.500}",
                    600: "{slate.600}",
                    700: "{slate.700}",
                    800: "{slate.800}",
                    900: "{slate.900}",
                    950: "{slate.950}"
                }
            }
        }
    },
    components: {
        message: {
            colorScheme: {
                dark: {
                    root: {
                        contentPadding: "10px",
                        borderWidth: "0 0 0 32px !important",
                        infoBackground: "{primary.100}",
                        warnBackground: "{amber.100}",
                        errorBackground: "{red.100}",
                        contentGap: "10px"
                    }
                }
            }
        },
        button: {
            colorScheme: {
                dark: {
                    root: {
                        dangerColor: "{red.100}"
                    }
                }
            }
        },
        tree: {
            colorScheme: {
                dark: {
                    root: {
                        background: "{surface.800}",
                        nodeHoverBackground: "{surface.700}"
                    }
                }
            }
        }
    }
});

export default theme;
