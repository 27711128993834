<script setup lang="ts">
import { inject, ref, toRef, watch, computed } from "vue";
import { DB_KEY } from "@/db";
import type { Table } from "dexie";
import type { CalibratedVoltages } from "@/types";
import Maplibre from "@/components/MapLibre.vue";
import { bbox, points } from "@turf/turf";
import type { BBox2d } from "@turf/helpers/dist/js/lib/geojson";
import { injectionKey } from "@/plugins/mapregistry";
import { until, useAsyncState } from "@vueuse/core";
import AssetDetails from "@/components/AssetDetails.vue";
import { Link, router } from "@inertiajs/vue3";
import AssetSidebarFiltering from "@/layout/AssetSidebarFiltering.vue";
import { useFilterScopeStore } from "@/store/filterscope";
import AssetSidebarItem from "@/components/AssetSidebarItem.vue";
import type { Collection } from "dexie";
import type { AssetTableItem } from "@/db";
import { useLatestPositionsStore } from "@/store/positions";
import MapLayers from "@/components/MapLayers.vue";
import { useSelectAssetStore } from "@/store/selected";
import MapFollowAsset from "@/components/MapFollowAsset.vue";
import MapLayerMoving from "@/components/MapLayerMoving.vue";
import MapPopup from "@/components/MapPopup.vue";
import MapTripView from "@/components/MapTripView.vue";
import MapUtilisationView from "@/components/icons/MapUtilisationView.vue";
import { isVehicle } from "@/utils/asset-types";
import type { TripResponse } from "@/utils/trip";
import LayoutFull from "@/layout/LayoutFull.vue";

const db = inject(DB_KEY);

const latestPositions = useLatestPositionsStore();

let initialCentering = false;
const { state: assetsFound, isReady: assetListReady } = useAsyncState(
    async () => {
        const allAssets = await db.assets.toCollection().sortBy("id");

        return allAssets;
    },
    [],
    { immediate: true }
);

(async () => {
    // Handling for when user logs in and loads TT for the first time.
    // Shared Worker takes care of syncing asynchronously in the background.
    // If there is no assets apparent, keep trying until there is at least one.
    await until(assetListReady).toBe(true);
    const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));
    while (assetsFound.value.length === 0) {
        await sleep(1000);
        assetsFound.value = await db.assets.toArray();
        console.log("reloading asset list until there is data...");
    }
})();

const filteredImeis = computed(() => {
    if (!assetsFound.value?.length) {
        return [];
    }

    return assetsFound.value.map((a) => a.imei).filter((e) => e?.length > 0);
});

const filterState = useFilterScopeStore();
filterState.$subscribe(
    async (mutation, state) => {
        // @ts-ignore
        let q: Collection<AssetTableItem, string> | Table<AssetSidebarItem>;

        if (state.containers.length > 0 && state.assetTypes.length > 0) {
            // @ts-ignore
            q = db.assets
                .where("container_id")
                .anyOf(state.containers)
                .and((asset) => state.assetTypes.includes(asset.type));
        } else if (state.containers.length > 0) {
            // @ts-ignore
            q = db.assets.where("container_id").anyOf(state.containers);
        } else if (state.assetTypes.length > 0) {
            // @ts-ignore
            q = db.assets.where("type").anyOf(state.assetTypes);
        } else if (state.fuzzyMatch === "") {
            assetsFound.value = await db.assets.toArray();
            return;
        } else {
            q = db.assets;
        }

        if (filterState.hasFuzzyFilter) {
            q = q.filter((row) => {
                return new RegExp(filterState.fuzzyMatch, "i").test(row.name);
            });
        }

        assetsFound.value = await q.toArray();
    },
    { immediate: true }
);

interface ContainerItemV2 {
    id: string;
    name: string;
    parent_container_id?: string;
    asset_count: number;
}

type NewContainerlist = { [key: string]: ContainerItemV2 };

const bounds = ref<{ boundary: BBox2d }>({ boundary: [0, 0, 0, 0] });
const registeredMap = inject(injectionKey).get();
const isLoaded = toRef(registeredMap, "isLoaded");
const selectedAssetStore = useSelectAssetStore();

const props = defineProps<{
    containers: NewContainerlist;
    maptiler_key: string;
    asset_id?: string;
    calibrated_voltages?: CalibratedVoltages;
    trip?: TripResponse;
}>();

const containerItems = toRef(props, "containers");
const assetId = toRef(props, "asset_id");
const trip = toRef(props, "trip");
const selectedAsset = ref<AssetTableItem | null>(null);

watch(
    assetId,
    async () => {
        if (assetId.value) {
            const result = await db.assets.where("hardware_id").equals(assetId.value).first();
            selectedAssetStore.selectAsset(selectedAsset.value);
            selectedAsset.value = result;
        } else {
            selectedAsset.value = null;
            selectedAssetStore.reset();
        }
    },
    { immediate: true }
);

// TODO: Begone recentre.
const recentre = async () => {
    let results = await db.coordinator.toArray();

    while (results.length === 0) {
        await new Promise((resolve) => setTimeout(resolve, 1000));

        results = await db.coordinator.toArray();
    }

    const locationPoints = points(results.filter((r) => r.latitude != "" && r.longitude != "").map((x) => [parseFloat(x.longitude), parseFloat(x.latitude)]));
    const bounding = bbox(locationPoints);

    if (locationPoints.features.length > 0) {
        bounds.value.boundary = [bounding[0], bounding[1], bounding[2], bounding[3]];
    }
};

// TODO: Update this to a 'watchOnce' or emit.
watch(
    () => isLoaded,
    async (mapLoaded) => {
        if (mapLoaded && !initialCentering) {
            if (assetId?.value) {
                console.log("WAIT...");
                await latestPositions.waitForReady();
                await until(selectedAsset).toBeTruthy();

                const currentPos = latestPositions.allPositions.get(selectedAsset.value.imei);
                if (!currentPos) {
                    // Tracker hasn't come online yet.
                    await recentre();
                    return;
                }
                const locationPoints = points([[parseFloat(currentPos.value.longitude), parseFloat(currentPos.value.latitude)]]);
                const bounding = bbox(locationPoints);
                if (locationPoints.features.length > 0) {
                    bounds.value = {
                        boundary: [bounding[0], bounding[1], bounding[2], bounding[3]]
                    };
                }
            } else {
                await recentre();
            }
            initialCentering = true;
        }
    },
    { immediate: true }
);
</script>

<template>
    <LayoutFull v-if="latestPositions.waitForReady()" class="" @keydown.esc="router.get(`/map`, {}, { replace: true, preserveState: true })">
        <div class="relative flex flex-row items-start bottom-0 h-full w-full overflow-hidden lg:absolute lg:py-4 bg-surface-900">
            <div class="flex flex-col h-full lg:max-w-[22rem] lg:relative lg:ml-6 w-full">
                <div class="flex-none z-10 card p-0 mb-0 lg:mb-4 max-lg:rounded-none">
                    <AssetSidebarFiltering :containers="containerItems" />
                </div>
                <div class="card z-10 grow h-full p-0 max-lg:rounded-none overflow-hidden">
                    <VirtualScroller class="h-full p-0" scrollHeight="flex" :items="assetsFound" :delay="25" :item-size="50">
                        <template v-slot:item="{ item }: { item: AssetTableItem }">
                            <AssetSidebarItem :site="containerItems[item.container_id]?.name ?? '~'" :key="item.id" :device="item" :is-focused="item.id === selectedAsset?.id" />
                        </template>
                    </VirtualScroller>
                </div>
            </div>
            <div class="absolute bottom-0 w-full z-10 max-lg:h-full lg:ml-6 lg:max-w-lg lg:relative" v-if="selectedAsset">
                <ScrollPanel class="rounded-xl items-start justify-start max-h-full h-full lg:h-auto max-lg:bg-surface-900">
                    <AssetDetails :key="selectedAsset.id" :containers="containerItems" :cb="calibrated_voltages" :selected-asset="selectedAsset" :selected-trip="trip" class="card max-lg:border-b-0">
                        <Link preserve-state as="div" href="/map" class="top-0 right-0 text-white font-bold rounded-border" style="">
                            <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined mb-2" />
                        </Link>
                    </AssetDetails>
                </ScrollPanel>
            </div>
        </div>

        <Maplibre :bounds="bounds" classes="top-0 w-full h-full max-lg:max-h-[50vh]" :tilingKey="props.maptiler_key">
            <MapLayers v-show="assetsFound.length > 0">
                <MapLayerMoving />
                <MapFollowAsset v-if="selectedAsset && !trip" :selected="selectedAsset" />

                <MapTripView :ttid="selectedAsset.hardware_id" v-if="trip && isVehicle(selectedAsset.type)" :trip="trip" :assetType="selectedAsset.type" />
                <MapUtilisationView :ttid="selectedAsset.hardware_id" v-if="trip && !isVehicle(selectedAsset.type)" :trip="trip" :assetType="selectedAsset.type" />
            </MapLayers>
            <template v-for="[imei, where] in latestPositions.allPositions">
                <MapPopup :selected="selectedAsset" :key="imei" v-if="!trip && selectedAsset?.imei == where.value.id" :rn="where" />
            </template>
        </Maplibre>
    </LayoutFull>
</template>

<style lang="scss">
#asset-trips {
    border-radius: 0;
}

@media screen and (min-width: 992px) {
    .maxw {
        max-width: 34rem !important;
    }
}

.p-scrollpanel {
    .p-scrollpanel-bar {
        background: #6844ad !important;
        width: 16px;
    }

    .p-scrollpanel-content {
        padding-bottom: 0;
    }
}
</style>
