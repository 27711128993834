import { createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  id: "Layer_2",
  "data-name": "Layer 2",
  viewBox: "0 0 21.01 21.96"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("defs", null, [
      (_openBlock(), _createBlock(_resolveDynamicComponent("style"), null, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode(".cls-2{stroke-linecap:round}.cls-2,.cls-3{fill:none;stroke:#a5a9af;stroke-width:2px}")
        ])),
        _: 1
      }))
    ]),
    _cache[1] || (_cache[1] = _createStaticVNode("<g id=\"Layer_1-2\" data-name=\"Layer 1\"><path d=\"M21.01 15.96v2c0 .55-.44 1-1 1h-1a2 2 0 1 0-4 0h-6v-5c0-1.1.9-2 2-2h6c2.21 0 4 1.79 4 4\" style=\"fill:#a5a9af;stroke-width:0;\"></path><circle cx=\"2.5\" cy=\"2.5\" r=\"1.5\" class=\"cls-3\"></circle><circle cx=\"2.5\" cy=\"7.5\" r=\"1.5\" class=\"cls-3\"></circle><circle cx=\"11.5\" cy=\"7.5\" r=\"1.5\" class=\"cls-3\"></circle><circle cx=\"11.5\" cy=\"2.5\" r=\"1.5\" class=\"cls-3\"></circle><path id=\"Path_28\" d=\"M3 5h8\" class=\"cls-2\" data-name=\"Path 28\"></path><path id=\"Path_32\" d=\"M3.01 18.86h12\" class=\"cls-2\" data-name=\"Path 32\"></path><path id=\"Path_29\" d=\"M7 5v14\" class=\"cls-3\" data-name=\"Path 29\"></path><circle cx=\"17.01\" cy=\"18.96\" r=\"2\" class=\"cls-3\"></circle></g>", 1))
  ]))
}
export default { render: render }