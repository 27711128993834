<script setup lang="ts">
import { computed, ref, toRef } from "vue";
import type { ServicingReportResult, ServicingReportResultItem } from "@/types";
import { DateTime } from "luxon";

const props = defineProps<{
    results: ServicingReportResult;
}>();

const results = toRef(props, "results");

const reportGenerateTime = DateTime.now();

const content = ref([{ icon: "pi pi-fw pi-phone", title: "Report Generated", info: reportGenerateTime.toLocaleString(DateTime.DATETIME_FULL) }]);

// eslint-disable-next-line vue/return-in-computed-property
const dueSoon = computed(() => {
    return results.value.assets.filter((r) => r.dueAtHours - r.currentEstimatedHours > 0);
});

const overdue = computed(() => {
    return results.value.assets
        .filter((r) => r.dueAtHours - r.currentEstimatedHours < 0)
        .map((r) => {
            r["excess"] = r.currentEstimatedHours - r.dueAtHours;
            return r;
        });
});
</script>
<template>
    <div id="printable">
        <div class="flex m-2">
            <div class="font-bold text-5xl text-surface-900 dark:text-surface-0 mb-4 the-heading">Servicing Report</div>
        </div>

        <div class="flex md:flex-row" :style="{ columnGap: '2rem', rowGap: '2rem' }">
            <div v-for="(item, i) in content" :key="i" class="flex flex-initial grow flex-col justify-center text-center border border-surface py-4 px-6 rounded-border">
                <span class="text-surface-900 dark:text-surface-0 font-bold mb-1">{{ item.title }}</span>
                <span class="text-surface-500 dark:text-surface-300">{{ item.info }}</span>
            </div>
        </div>
        <br />

        <h2 class="font-bold text-3xl m-4">Upcoming Service</h2>
        <DataTable :sortOrder="-1" showGridlines :value="dueSoon" resizableColumns columnResizeMode="fit" scrollable tableStyle="width: 100%">
            <Column field="contractId" header="Contract">
                <template #body="{ data }: { data: ServicingReportResultItem }">
                    {{ data.contractId == "" ? "-" : data.contractId }}
                </template>
            </Column>
            <Column field="assetName" header="Name"></Column>
            <Column field="assetType" header="Asset Type">
                <template #body=""> Light Tower </template>
            </Column>
            <Column field="currentEstimatedHours" sortable header="Engine Hours">
                <template #body="{ data }: { data: ServicingReportResultItem }"> {{ data.currentEstimatedHours }} (Due {{ data.dueAtHours }}) </template>
            </Column>
            <Column field="site" header="Depot" sortable>
                <template #body="{ data }: { data: ServicingReportResultItem }">
                    {{ data.site }}
                </template>
            </Column>
            <Column header="Hire Status">
                <template #body="{ data }: { data: ServicingReportResultItem }">
                    {{ data.contractId != "" ? "On-hire" : "Off-hire" }}
                </template>
            </Column>
        </DataTable>

        <h2 class="font-bold text-3xl m-4">Overdue</h2>
        <DataTable :sortOrder="-1" sort-field="excess" showGridlines :value="overdue" resizableColumns columnResizeMode="fit" scrollable tableStyle="width: 100%">
            <Column field="contractId" header="Contract">
                <template #body="{ data }: { data: ServicingReportResultItem }">
                    {{ data.contractId == "" ? "-" : data.contractId }}
                </template>
            </Column>
            <Column field="assetName" header="Name"></Column>
            <Column field="assetType" header="Asset Type">
                <template #body=""> Light Tower </template>
            </Column>
            <Column field="currentEstimatedHours" sortable header="Engine Hours">
                <template #body="{ data }: { data: ServicingReportResultItem }"> {{ data.currentEstimatedHours }} (Due {{ data.dueAtHours }}) </template>
            </Column>
            <Column field="excess" header="Excess" sortable>
                <template #body="{ data }: { data: ServicingReportResultItem }">
                    {{ data.currentEstimatedHours - data.dueAtHours }}
                </template>
            </Column>

            <Column field="site" header="Depot" sortable>
                <template #body="{ data }: { data: ServicingReportResultItem }">
                    {{ data.site }}
                </template>
            </Column>
            <Column header="Hire Status">
                <template #body="{ data }: { data: ServicingReportResultItem }">
                    {{ data.contractId != "" ? "On-hire" : "Off-hire" }}
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<style lang="css">
@media print {
    @page {
        size: landscape;
    }

    .text-700 {
        color: #000 !important;
    }

    .text-xl {
        color: #000 !important;
    }

    .p-datatable .p-datatable-thead > tr > th {
        color: #000 !important;
    }

    .p-datatable .p-datatable-tbody > tr > td {
        padding: 0.3rem !important;
    }

    .p-datatable .p-datatable-tbody > tr {
        color: #000 !important;
    }

    .printme {
        display: none !important;
    }

    body {
        color: #000 !important;
        text-align: center !important;
    }

    .the-heading {
        text-align: center !important;
    }

    #printable {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 99999;
        background: #000000;
        height: 100vh;
    }

    .layout-sidebar {
        display: none;
    }
}

.layout-dim .p-datatable-tbody > tr {
    background: #071426 !important;
}

.p-datatable-header {
    background: var(--surface-section) !important;
}

.p-datatable .p-datatable-thead th {
    background: var(--surface-section) !important;
}

.p-progressbar {
    width: 100%;
}
</style>
